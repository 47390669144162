import { useState, useRef, useEffect } from "react";
import * as THREE from 'three';
import {OrbitControls} from "./OrbitControls";
import {OBJLoader} from "./OBJLoader";
import {MTLLoader} from "./MTLLoader";

const style = {
    height: '100vh'// we can control scene size by setting container dimensions
};

function Coin(props) {
    const thisRef = useRef({});
    const mountRef = useRef(null);
    const loading = useRef(false);
    const rotateRef = useRef(1);

    const completed = useRef(false);
      
    useEffect(() => {
        // Check the screen size when the component mounts
        const mediaQuery = window.matchMedia('(max-width: 576px)');
        
        thisRef.current.sceneSetup();
      
        // if (mediaQuery.matches) {
        //     thisRef.current.sceneSetupMobile();
        // }else {
        //     thisRef.current.sceneSetup();
        // }
       
        thisRef.current.addLights();
        thisRef.current.loadTheModel();
        thisRef.current.startAnimationLoop();
        window.addEventListener('resize', thisRef.current.handleWindowResize);
        
        return () => {
            window.removeEventListener('resize', thisRef.current.handleWindowResize);
            window.cancelAnimationFrame(thisRef.current.requestID);
            thisRef.current.controls.dispose();
        }
    }, [props.blur]);

    useEffect(() => {
        if (thisRef.current.model) {
            if(props.animate) {
                console.log('animate changed',props.animate)
                thisRef.current.startAnimationLoop();
            } else {
                console.log('reset coin rotation')
                rotateRef.current = 1;
                thisRef.current.model.rotation.z = 0;
            }
        }
    }, [props.animate])
    
    // Standard scene setup in Three.js. Check "Creating a scene" manual for more information
    // https://threejs.org/docs/#manual/en/introduction/Creating-a-scene
    thisRef.current.sceneSetup = () => {
        // get container dimensions and use them for scene sizing
        const width = mountRef.current?.clientWidth;
        const height = mountRef.current?.clientHeight;
        
        if(!thisRef.current.camera) {

            thisRef.current.scene = new THREE.Scene();

            const mediaQuery = window.matchMedia('(max-width: 576px)');
            if (mediaQuery.matches) {
                console.log("mobileeee");
                thisRef.current.camera = new THREE.PerspectiveCamera(
                    27, // fov = field of view
                    width / height, // aspect ratio
                    0.1, // near plane
                    27 // far plane
                );
            }else {
                console.log("desktoppppp");
                thisRef.current.camera = new THREE.PerspectiveCamera(
                    20, // fov = field of view
                    width / height, // aspect ratio
                    0.1, // near plane
                    20 // far plane
                );
            }
        
            thisRef.current.camera.position.z = 2.5; // is used here to set some distance from a cube that is located at z = 0
        
            // OrbitControls allow a camera to orbit around the object
            // https://threejs.org/docs/#examples/controls/OrbitControls
            thisRef.current.controls = new OrbitControls( thisRef.current.camera, mountRef.current );
            thisRef.current.controls.minDistance = 2;
            thisRef.current.controls.maxDistance = 5;
            thisRef.current.renderer = new THREE.WebGLRenderer({ alpha: true });
            thisRef.current.renderer.setSize( width, height );
            thisRef.current.renderer.setClearColor( 0x000000, 0 ); // the default
            mountRef.current.appendChild( thisRef.current.renderer.domElement ); // mount using React ref
    
        }
    };

    // thisRef.current.sceneSetupMobile = () => {
    //     // get container dimensions and use them for scene sizing
    //     const width = mountRef.current?.clientWidth;
    //     const height = mountRef.current?.clientHeight;
        
    //     if(!thisRef.current.camera) {

    //         thisRef.current.scene = new THREE.Scene();

    //         thisRef.current.camera = new THREE.PerspectiveCamera(
    //             27, // fov = field of view
    //             width / height, // aspect ratio
    //             0.1, // near plane
    //             27 // far plane
    //         );
        
    //         thisRef.current.camera.position.z = 2.5; // is used here to set some distance from a cube that is located at z = 0
        
    //         // OrbitControls allow a camera to orbit around the object
    //         // https://threejs.org/docs/#examples/controls/OrbitControls
    //         thisRef.current.controls = new OrbitControls( thisRef.current.camera, mountRef.current );
    //         thisRef.current.controls.minDistance = 2;
    //         thisRef.current.controls.maxDistance = 5;
    //         thisRef.current.renderer = new THREE.WebGLRenderer({ alpha: true });
    //         thisRef.current.renderer.setSize( width, height );
    //         thisRef.current.renderer.setClearColor( 0x000000, 0 ); // the default
    //         mountRef.current.appendChild( thisRef.current.renderer.domElement ); // mount using React ref
    
    //     }
    // };

    // Code below is taken from Three.js OBJ Loader example
    // https://threejs.org/docs/#examples/en/loaders/OBJLoader
    thisRef.current.loadTheModel = () => {
        // instantiate a loader
        if(loading.current==false) {
        loading.current = true;
        const loader = new MTLLoader()
            .setPath( 'model/' )
            .load( 'BV_Coin_LowPoly.mtl', function ( materials ) {
                console.log("material loaded")
                materials.preload();
                new OBJLoader()
                    .setMaterials( materials )
                    .setPath( 'model/' )
                    .load( 'BV_Coin_7.obj', function ( object ) {
                        object.scale.setScalar( 0.01 );
                        let base_obj=new  THREE.Group();
                        base_obj.add(object)
                        base_obj.position.set(0, 0, 0)
                        object.position.set(0, 0, 0)
                        base_obj.rotateX(3.1415926/2)
                        // get the newly added object by name specified in the OBJ model (that is Elephant_4 in my case)
                        // you can always set console.log(this.scene) and check its children to know the name of a model
                        thisRef.current.scene.add(base_obj);
              
                        console.log(thisRef.current.scene) 
                        
                        // centering the model
                        // THREE.Box3() is AABB (axis-aligned bounding box). You can set it from the object you've loaded.
                        // Then use .getCenter() method to get its center. Then simply subtract the vector of the center
                        // from the default position of the object. https://threejs.org/docs/index.html#api/en/math/Box3
                        

                        // make this element available inside of the whole component to do any animation later
                        
                        //object.position.y = - 0.95;
                        
                        
                        
                        thisRef.current.model = base_obj;
                        
                    }, 
            ( xhr ) => {

                const loadingPercentage = Math.ceil(xhr.loaded / xhr.total * 100);
                console.log( ( loadingPercentage ) + '% loaded' );

                // update parent react component to display loading percentage
                props.onProgress(loadingPercentage);
            },
            // called when loading has errors
            ( error ) => {

                console.log( 'An error happened:' + error );

            }
            );

          } );
      
    }
  }
    // adding some lights to the scene
    thisRef.current.addLights = () => {
        console.log("adding more light");
        const lights = [];

        // set color and intensity of lights
        lights[ 0 ] = new THREE.AmbientLight( 0xffffff, );
        lights[ 1 ] = new THREE.PointLight( 0xffffff, 0.5);

        // place some lights around the scene for best looks and feel
        lights[ 0 ].position.set( 0, 2000, 0 );
        lights[ 1 ].position.set( 10, 20, 10 );

        thisRef.current.scene.add( lights[ 0 ] );
        thisRef.current.scene.add( lights[ 1 ] );
    };

    thisRef.current.startAnimationLoop = () => {
        // slowly rotate an object
        if (thisRef.current.model) {
            if(props.animate) {
          
              if(rotateRef.current > 0.05) { 
                thisRef.current.model.rotation.z -= rotateRef.current;
                rotateRef.current*=0.97
                // console.log( rotateRef.current)
              } else {
                let rotated = thisRef.current.model.rotation.z%3.14;
                
                if(rotated > -0.1 && parseInt(thisRef.current.model.rotation.z/3.14)%2==0) {
                    if(completed.current==false) {
                        completed.current = true;
                        props.onComplete(true);
                    }
                } else {
                    thisRef.current.model.rotation.z -= rotateRef.current;  
                    if(completed.current==true) {completed.current=false;}
                }
              }
            }
            
        }
        thisRef.current.renderer.render( thisRef.current.scene, thisRef.current.camera );

        // The window.requestAnimationFrame() method tells the browser that you wish to perform
        // an animation and requests that the browser call a specified function
        // to update an animation before the next repaint
        thisRef.current.requestID = window.requestAnimationFrame(thisRef.current.startAnimationLoop);
    };

    thisRef.current.handleWindowResize = () => {
        
        const width = mountRef.current?.clientWidth;
        const height = mountRef.current?.clientHeight;
    
        thisRef.current.renderer.setSize( width, height );
        thisRef.current.camera.aspect = width / height;

        // Note that after making changes to most of camera properties you have to call
        // .updateProjectionMatrix for the changes to take effect.
        thisRef.current.camera.updateProjectionMatrix();
        console.log('resized');
    };
    
  return (
    <div style={style} ref={mountRef} />
  );
}



const Container = ({animate, onComplete}) => {
    const [state, setState] = useState({ isMounted: true });

    const {isMounted = true, loadingPercentage = 0} = state;
    return (
        <div className="absolute w-screen h-screen top-[2vh] sm:top-0 left-0">
            {isMounted && <Coin  onProgress={loadingPercentage => setState(state => ({ ...state, loadingPercentage }))}  onComplete={onComplete} animate={animate} />}
            
        </div>
    )
}

export default Container;