import React, { useEffect, useState, useRef } from "react";
import { useMotionValue, useMotionValueEvent, useScroll, useTransform, useInView, motion, progress } from "framer-motion";

const DivasMobile = ({auto_show_girls}) => {


    const timer_ref = useRef();
    const timer2_ref = useRef();
    const timer3_ref = useRef();

    const [image_index, setImageIndex] = useState(1);
    const [fadeIn, setFadeIn] = useState(false);

    const index_test = 3;

    const items = [
        {
            // image: require("../assets/black1.png"),
            image: require("../assets/BV_girl_01.png"),
            height: 90,
            left: 10,
            name: "Anne Hathaway"
        },
        {
            // image: require("../assets/black2.png"),
            image: require("../assets/BV_girl_02.png"),
            height: 90,
            left: 10,
            name: "Zendaya"
        },
        {
            // image: require("../assets/black3.png"),
            image: require("../assets/BV_girl_03.png"),
            height: 90,
            left: -8,
            name: "Lisa"
        },
        {
            // image: require("../assets/black4.png"),
            image: require("../assets/BV_girl_04.png"),
            height: 90,
            left: -1,
            name: "舒淇"
        }
    ];

    useEffect(() => {
        if (auto_show_girls) {
            if (timer_ref.current != null) {
                clearInterval(timer_ref.current);
                timer_ref.current = setInterval(() => {
                    setImageIndex((old) => {
                        return old >= items.length - 1 ? 0 : old + 1;
                    });
                }, 2200);
            }else {
                console.log("aqui");
                timer_ref.current = setInterval(() => {
                    setImageIndex((old) => {
                        return old >= items.length - 1 ? 0 : old + 1;
                    });
                }, 2200);
            }
        }else {
            setImageIndex(0);
            setFadeIn(false);
            clearInterval(timer_ref.current);
            clearTimeout(timer2_ref.current);
            clearTimeout(timer3_ref.current);
        }
    }, [auto_show_girls]);

    useEffect(() => {
        if (auto_show_girls) {
            clearTimeout(timer3_ref.current);
            timer3_ref.current = setTimeout(() => {
                setFadeIn(true); // Trigger fade-in animation
            }, 300);
            clearTimeout(timer2_ref.current);
            timer2_ref.current = setTimeout(() => {
                setFadeIn(false);
            }, 1500);
        } 
    }, [auto_show_girls, image_index]); // Depend on image_index to trigger on change


    return (
        <div className="w-full flex-1 flex items-center justify-center relative">
            
            <div className="absolute top-0 w-screen h-full z-[6]">
                <div className="relative flex  w-screen h-full items-end justify-center">
                    <motion.img src = {items[image_index].image} 
                        className={``}
                        initial={{ opacity: 1 }}
                        animate={{ opacity: fadeIn ? 1 : 0 }}
                        transition={{ duration: .6}}
                        style={{
                            height: items[image_index].height + '%',
                            marginLeft: items[image_index].left + 'vw',
                        }}
                    >
                    </motion.img>
                    <motion.div className={`absolute left-[10vw] bottom-12 text-white z-[30] gen-ryu text-[15px]`} 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: fadeIn ? 1 : 0 }}
                        transition={{ duration: .6}}
                    >
                        {items[image_index].name}
                    </motion.div> 
                </div>
            </div>
            {/* <div className="absolute bottom-0 z-[6] flex">
                {/* <motion.img src = {require("../assets/woman2.png")}  
                <div className="relative flex bg-red-900 w-screen h-full">
                    <motion.img src = {items[index_test].image} 
                        className={`w-full relative object-contain`}
                        // initial={{ opacity: 1 }}
                        // animate={{ opacity: fadeIn ? 1 : 0 }}
                        // transition={{ duration: .6}}
                        style={{
                            height: "100%",
                            marginLeft: items[index_test].left + 'vw',
                        }}
                    >
                    </motion.img>
                    <motion.div className={`absolute left-[10vw] bottom-12 text-white z-[30] gen-ryu text-[15px]`} 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: fadeIn ? 1 : 0 }}
                        transition={{ duration: .6}}
                    >
                        {items[image_index].name}
                    </motion.div>
                </div>
  
            </div> */}

        </div>
    )
}

export default DivasMobile;