import React, { useState, useRef, useEffect } from "react";
import { useMotionValue, useMotionValueEvent, useScroll, useTransform, useInView, motion, useAnimation } from "framer-motion";
import DownArrow from "./DownArrow";
import ItemsDesktop from "./ItemsDesktop";
import ItemsMobile from "./ItemsMobile";
import { getDeviceViewportHeight } from "../hooks/GetDeviceHeight";
import LineDot from "./LineDot";

const SerpentiNewSection = ({setColor, setShowBG}) => {

    useEffect(() => {
        
        // prevent horizontal scroll because of the lines scale

        const preventHorizontalScroll = (event) => {
          if (window.scrollX !== 0) {
            // Reset horizontal scroll to 0 if it happens
            window.scrollTo(0, window.scrollY);
          }
        };
    
        window.addEventListener('scroll', preventHorizontalScroll);
    
        return () => {
          window.removeEventListener('scroll', preventHorizontalScroll);
        };
    }, []);

    const [marginTopMobile, setMarginTopMobile] = useState(false);

    // Function to update margin based on screen width
    const updateMarginTop = () => {
        if (window.innerWidth < 576) { // Define breakpoint for mobile
            setMarginTopMobile(true);
        } else {
            setMarginTopMobile(false);
        }
    };

    useEffect(() => {
        // Initial check on component mount
        updateMarginTop();

        // Update margin on window resize
        window.addEventListener('resize', updateMarginTop);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', updateMarginTop);
    }, []);
    
    useEffect(() => {
        // Check the screen size when the component mounts
        const mediaQuery = window.matchMedia('(max-width: 576px)');
        setMarginTopMobile(mediaQuery.matches);
     
        // Event listener for screen size changes
        const handleMediaChange = (e) => {
            setMarginTopMobile(mediaQuery.matches);
        };
    
        // Add listener for resizing
        mediaQuery.addEventListener('change', handleMediaChange);
    
        // Clean up listener on unmount
        return () => mediaQuery.removeEventListener('change', handleMediaChange);
    }, []);

      
    const gradient_ref = useRef();  
    const woman_ref = useRef();

    const last_scroll_y = useRef();

    const gradient_opacity_last = useRef();

    const gradient_bottom_ref = useRef();

    const container1 = useRef();
    const container3 = useRef();

    const container_serperti = useRef();

    const serpenti_text = useRef();
    const green_container_1 = useRef();
    const green_container_2 = useRef();

    const woman_move_vh = useRef(0);
    const woman_move_vh_mobile = useRef(0);

    const star_lines_container = useRef();

    const text_bottom_container = useRef();

    const [fixed, setFixed] = useState(true);

    const [keep_scaling, setKeepScaling] = useState(false);
    const [keep_scaling2, setKeepScaling2] = useState(false);
    const [lineDone, setLineDone] = useState(false);
    const [fade_green, setFadeGreen] = useState(false);
    const [fade_green2, setFadeGreen2] = useState(false);

    const [showProduct, setProductShow] = useState(false);

    const [stop, setStop] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0); // Track last scroll position

    const [show_arrow, setShowArrow] = useState(false);
    const [show_arrow2, setShowArrow2] = useState(false);

    const { scrollYProgress: scrollYProgress1} = useScroll({
        target: gradient_ref,
        offset: ["start end", "start start"]
    });

    const { scrollYProgress: scrollYProgress2 } = useScroll({
        target: woman_ref,
        offset: ["start end", "start start"]
    });

    const { scrollYProgress: scrollYProgress3} = useScroll({
        target: container1,
        offset: [`start end`, "end end"]
    });

    const { scrollYProgress: scrollYProgress5} = useScroll({
        target: container3,
        offset: [`start center`, "end start"]
    });
  
    const { scrollYProgress: scrollYProgress6, scrollY: scrollY_Star} = useScroll({
        target: green_container_1,
        offset: [`start end`, "start start"]
    }); 
   
    const { scrollYProgress: scrollYProgress7} = useScroll({
        target: serpenti_text,
        offset: [`start start`, "end start"]
    });

    const { scrollYProgress: scrollYProgress8 } = useScroll({
        target: container_serperti,
        offset: [`start end`, "start start"]
    });

    const { scrollYProgress: scrollYProgress9 } = useScroll({
        target: gradient_opacity_last,
        offset: [`start start`, "end start"]
    });
    
    const { scrollYProgress: scrollYProgress10 } = useScroll({
        target: container_serperti,
        offset: [`end end`, "end start"]
    });

    const { scrollYProgress: scrollYProgress11 } = useScroll({
        target: text_bottom_container,
        offset: [`start start`, "end start"]
    });

    const { scrollYProgress: scrollYProgress12 } = useScroll({
        target: star_lines_container,
        offset: [`start ${100}px`, `end ${300}px`]
    });
    
    const { scrollYProgress: scrollYProgress13} = useScroll({
        target: green_container_2,
        offset: [`start end`, "start start"]
    });

    useMotionValueEvent(scrollYProgress2, "change", (progress) => {
        if(progress >= 0.5) {
            setProductShow(true)
        } else {
            setProductShow(false)
        }
    })

    const screenProp = window.innerWidth / (window.innerHeight /2);

    // desktop
    // woman_move_vh.current = ((1.825 - screenProp)*57.14286) - 95; // ratio at 1.825 move -100vh, ratio at 2 move -110vh
    woman_move_vh.current = -105; // ratio at 1.825 move -100vh, ratio at 2 move -110vh
    
    // mobile
    woman_move_vh_mobile.current = ((1.825 - screenProp)*57.14286) -170; // ratio at 1.825 move -100vh, ratio at 2 move -110vh
   
    const video_translate = useTransform(scrollYProgress2, [0, 1], ['-100vh', woman_move_vh.current + 'vh']);
    const video_translate_mobile = useTransform(scrollYProgress2, [0, 1], ['-100vh', woman_move_vh_mobile.current + 'vh']);
   
    const opacity_serpenti_text = useTransform(scrollYProgress8, [0.75, 1], [0, 1]);
    
    const star_scale = useTransform(scrollYProgress8, [.7, 1], [0.5,1]);
   
    const lines_scale = useTransform(scrollYProgress8, [0.7, 1 ], [0, 2.5]);
    const lines_scale2 = useTransform(scrollYProgress8, [0.7, 1], [0, 2.5]);
    

    const star_opacity = useTransform(scrollYProgress10, [0, 1], [1,0]); 

    const translate_green_bg = useTransform(scrollYProgress6,[0,1],['0vh','-20vh']);

    const fadeout_text_bottom = useTransform(scrollYProgress11, [0, 1], [1, 0]);

    const star_lines1 = useTransform(scrollYProgress12, [0, .1], [0, 2.5]);
    const star_lines2 = useTransform(scrollYProgress12, [0, .2], [0, 2.5]);
    
    useMotionValueEvent(lines_scale, "change", (progress) => {
        if (progress > 0) {
            console.log("scaling 111");
            setKeepScaling(true);
        }else {
            console.log("scaling 111 false");
            setKeepScaling(false);
        }
    });

    useMotionValueEvent(lines_scale2, "change", (progress) => {
        // console.log('star_lines 2', progress)
        if (progress >= 2.4) {
            // console.log("line two doneeee")

            console.log("scaling 222");
            setKeepScaling2(true);
        }else {


            console.log("scaling 222 false");
            setKeepScaling2(false);
        }
    });

    useMotionValueEvent(lines_scale, "change", (progress) => {
        // console.log('star_lines 1', progress)
        if (progress >= 2.5) {
            setLineDone(true);
        }else {
            setLineDone(false);
        }
    });

    // green section
    useMotionValueEvent(scrollYProgress6, "change", (progress) => {
        // console.log("green section", progress)
        
        if (progress > 0) {
            setFadeGreen(true);
            setColor("green");
            setShowBG(true);
        }

        if (progress <= .8) {
            setFadeGreen(false);
            setColor("yellow");
        }

    });

    useMotionValueEvent(scrollYProgress13, "change", (progress) => {
        // console.log("green section", progress)
        
        if (progress > 0) {
            setFadeGreen2(true);
        }

        if (progress <= .8) {
            setFadeGreen2(false);
        }

    });
    
    // star section
    useMotionValueEvent(scrollYProgress8, "change", (progress) => {
        if (progress == 1) {
            setShowArrow2(true);
        }else{
            setShowArrow2(false);
        }
    });

    return (
        <div className="relative z-[6] "
            ref={woman_ref}>
          
            <div className="snap-start snap-stop h-[100vh] exclude-snap">
             
                <motion.video src = {require("../assets/video/taylor_05.mp4")} loop = {true} muted = {true} autoPlay = {true} playsInline = {true}
                    className="absolute w-full z-[1] h-[70%] xs:h-[95%] object-cover"
                    style={{
                        marginTop: (marginTopMobile) ? `calc(${video_translate_mobile.get()} - 1vh)` : video_translate,
                    }}
                >
                </motion.video>
                
                <div className="flex flex-col items-center justify-end h-[unset] relative">
                 
                    <motion.div className={`h-[80px] flex items-center flex-col justify-between mb-[50px] mt-[35vh] xs:mt-[35vh] 2xl:mt-[40vh] z-[9] ${showProduct ? 'animate-fadeIn' : ''}`}
                        style={{
                            opacity: 0,
                        }}
                    >
                        <img src = {require("../assets/star.png")} className="max-w-[30px]"></img>
                        <div className="text-white text-spacing text-center !mb-0 text-[1.2rem] times-new">SERPENTI</div>
                    </motion.div>
                    
                    {/* products */}
                    {
                        (marginTopMobile) ? <ItemsMobile showProduct={showProduct}></ItemsMobile> : 
                        null
                    }

                    <motion.div className="absolute sm:hidden top-0 w-screen z-[1000] flex items-end justify-center pb-[15px]"
                        style={{
                            height: getDeviceViewportHeight()
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: showProduct ? 1 : 0 }}
                        transition={{ duration: .6}}
                    >  
                        <DownArrow></DownArrow>
                    </motion.div>

                </div>
                   
                {/* products */}
                {
                    (marginTopMobile) ? null : 
                    <ItemsDesktop showProduct={showProduct} serpenti_text = {serpenti_text}></ItemsDesktop>
                }

            </div>
            
            {/* Green Section */}
            <div className="snap-center snap-stop relative h-[100vh] w-full flex flex-col z-[10] put-again-snap"
                ref = {green_container_1}
            >
                                    
                <motion.div className="absolute w-full  left-0 h-[120vh]  "
                    style = {{
                        background: 'linear-gradient(to bottom,  transparent, #014407 15%)',
                        top: translate_green_bg,
                    }}
                >
                </motion.div>

                {/* Green gradient background */}
                <motion.div className="w-screen flex flex-col items-center justify-around flex-1 relative">
                    
                    {/* mobile - tablet */}
                    <motion.div className="w-screen flex xs:hidden flex-col z-[4] items-center"
                        style={{
                            opacity: 1,
                        }}
                        ref={text_bottom_container}
                    >
                        <motion.div className="mb-[45px] text-bulgari-yellow title-mobile gen-ryu-bold "
                            style={{
                                textAlign:'center',lineHeight: '2'
                            }}
                            initial = {{opacity: 0}}
                            animate = {{opacity: fade_green ? 1 : 0}}
                            transition={{duration: .6}}
                        >
                            「靈蛇」<br></br>在古羅馬時期是非常重要的象徵，<br></br> 代表著力量、保護與智慧，生生不息。
                        </motion.div>
                        <motion.div className="content-mobile gen-ryu-sb text-center" 
                            style={{
                            }}
                            initial = {{opacity: 0}}
                            animate = {{opacity: fade_green ? 1 : 0}}
                            transition={{duration: .6}}
                        >
                            而這亦早已嵌入 <span class="letter-1-space">BVLGARI Serpenti</span> 系列之中。 <br></br>
                            靈蛇蛻變化為腕錶，蜿蜒在手腕之上，<br></br>
                            蛇頭作錶盤，詮釋時間的靈動。<br></br><br></br>

                            始於 40 年代後期的腕錶和珠寶世界，<br></br>
                            後來擴展到皮具領域。<br></br>
                            <span class="letter-1-space">Serpenti Baia Mini</span>  迷你手袋，<br></br>其 <span class="letter-1-space">Cobra </span>鏈條可以拆卸，<br></br>
                            可以獨立佩戴，這足見靈蛇百變之姿，<br></br>
                            不限於蜿蜒的指環或手環形狀。<br></br><br></br>

                        </motion.div> 
                    </motion.div>

                    {/* desktop */}
                    <motion.div className="w-screen hidden  xs:flex flex-col items-center justify-center pl-5 pr-5 z-[4]"
                        style={{
                            opacity: 1,
                        }}
                        ref={text_bottom_container}
                        >
                            
                        <motion.div className="text-bulgari-yellow mb-5 text-[1.2rem] gen-ryu-bold tracking-[9px]"
                            style={{
                                textAlign:'center',lineHeight: '2'
                            }}
                            initial = {{opacity: 0}}
                            animate = {{opacity: fade_green ? 1 : 0}}
                            transition={{duration: .6}}
                        >
                           「靈蛇」<br></br>在古羅馬時期是非常重要的象徵，<br></br> 代表著力量、保護與智慧，生生不息。
                    
                            {/* old text */}
                            {/* 「靈蛇」<br/>在古羅馬時期是非常重要的象徵 <br/><span className="w-[]">─ </span>重生與生命力 */}
                        </motion.div>
                        <motion.div className="text-white text-center white-content gen-ryu-sb" 
                            initial = {{opacity: 0}}
                            animate = {{opacity: fade_green ? 1 : 0}}
                            transition={{duration: .6}}
                        >
                            而這亦早已嵌入 <span class="letter-1-space">BVLGARI Serpenti</span> 系列之中。 <br></br>
                            靈蛇蛻變化為腕錶，蜿蜒在手腕之上，<br></br>
                            蛇頭作錶盤，詮釋時間的靈動。<br></br><br></br>

                            始於 40 年代後期的腕錶和珠寶世界，<br></br>
                            後來擴展到皮具領域。<br></br>
                            <span class="letter-1-space">Serpenti Baia Mini</span>  迷你手袋，其 <span class="letter-1-space">Cobra</span> 鏈條可以拆卸，<br></br>
                            可以獨立佩戴，這足見靈蛇百變之姿，<br></br>
                            不限於蜿蜒的指環或手環形狀。<br></br><br></br>

                            除此更會變奏成 <span class="letter-1-space">Aeterna Serpenti Anima</span> 及 <br></br>
                            <span class="letter-1-space">Aeterna Serpenti Fiamma</span> 香水系列，<br></br>
                            尤如靈蛇神秘蟄伏的姿態，出現於 <span class="letter-1-space">BVLGARI Serpenti</span> 不同設計種類之中。<br></br><br></br>

                            <span class="letter-1-space">Elizabeth Taylor</span> 在羅馬拍攝電影《埃及豔后》時，<br></br>
                            亦戴上了一枚 Serpenti 手錶，與她主演的角色形象不謀而合。<br></br><br></br>

                            七十多年來，<br></br>
                            這個經典標誌成功詮釋了每個時代的精神，<br></br>
                            成為女性賦權的象徵，並且代代相傳。

                            {/* old text */}
                            {/* 而這道力量亦早已嵌入 <span class="letter-1-space">BVLGARI Serpenti</span> 系列之中。<br></br>
                            靈蛇蛻變化爲腕錶，蜿蜒在手腕之上，<br></br>
                            蛇頭作錶盤，詮釋時間的靈動。<br></br> <br></br>

                            百變豈止首飾，<span class="letter-1-space">Serpenti Baia Mini</span> 迷你手袋，<br></br>
                            其 <span class="letter-1-space">Cobra</span> 鏈條可以拆卸，可以獨立佩戴，<br></br>這足見靈蛇百變之姿不限於蜿蜒的指環或手環形狀，<br></br>
                            除此更會變奏成 <span class="letter-1-space">Aeterna Serpenti Anima</span> 及 <br></br>
                            <span class="letter-1-space">Aeterna Serpenti Fiamma</span> 香水系列，<br></br>
                            尤如靈蛇神秘蟄伏的姿態，出現於 <span class="letter-1-space">BVLGARI</span> 不同設計種類之中。 <br></br> <br></br>

                            <span class="letter-1-space">Elizabeth Taylor</span> 在羅馬拍攝電影《埃及豔后》時，<br></br>
                            亦戴上了一枚 <span class="letter-1-space">Serpenti</span> 手錶，與她主演的角色形象不謀而合。 */}

                        </motion.div>
                    </motion.div>

                </motion.div>
                
                <motion.div className="absolute w-screen top-0 z-[1000] flex items-end justify-center pb-[15px]"
                    style = {{
                        height: getDeviceViewportHeight()
                    }}
                    initial = {{opacity: 0}}
                    animate = {{opacity: fade_green ? 1 : 0}}
                    transition={{duration: .6}}
                >
                    <DownArrow></DownArrow>
                </motion.div>
            
            </div>  
            
            <div className="snap-center snap-stop relative h-[100vh] w-full flex flex-col z-[10] put-again-snap xs:hidden bg-bulgari-green"
                ref={green_container_2}
            >
                <motion.div className="w-screen flex xs:hidden flex-col items-center justify-center z-[4] h-full relative">
                    <motion.div className="content-mobile text-center gen-ryu-sb" 
                        style={{
                        }}
                        initial = {{opacity: 0}}
                        animate = {{opacity: fade_green2 ? 1 : 0}}
                        transition={{duration: .6}}
                    >
                        除此更會變奏成 <span class="letter-1-space">Aeterna Serpenti Anima</span> 及 <br></br>
                        <span class="letter-1-space">Aeterna Serpenti Fiamma</span> 香水系列，<br></br>
                        尤如靈蛇神秘蟄伏的姿態，<br></br>出現於 <span class="letter-1-space">BVLGARI Serpenti</span> 不同設計種類之中。<br></br><br></br>

                        <span class="letter-1-space">Elizabeth Taylor</span> 在羅馬<br></br>拍攝電影 《埃及豔后》 時，<br></br>
                        亦戴上了一枚 Serpenti 手錶，<br></br>與她主演的角色形象不謀而合。<br></br><br></br>

                        七十多年來，<br></br>
                        這個經典標誌成功詮釋了每個時代的精神，<br></br>
                        成為女性賦權的象徵，並且代代相傳。 

                    </motion.div> 

                    <motion.div className="absolute w-screen top-0 z-[1000] flex items-end justify-center pb-[15px]"
                        style = {{
                            height: getDeviceViewportHeight()
                        }}
                        initial = {{opacity: 0}}
                        animate = {{opacity: fade_green2 ? 1 : 0}}
                        transition={{duration: .6}}
                    >
                        <DownArrow></DownArrow>
                    </motion.div>
                    
                </motion.div>
            </div>
            
            {/* Star section */}
            <motion.div className="snap-center snap-stop  h-[100vh] w-screen relative put-again-snap bg-bulgari-green" 
                ref={container_serperti}
            >
                
                {/* mobile - tablet text */}
                <div className={`absolute xs:hidden top-[16vh] left-[35vw] z-[100] ${lineDone ? 'animate-fadeIn' : ''}`}
                    style={{
                        opacity: 0,
                        animationDelay: '.6s'
                    }}
                >
                    <div className="text-white title-mobile tracking-[4px] gen-ryu"
                    >
                        二千年古羅馬文化
                    </div>
                    <div className="text-white title-mobile tracking-[4px] gen-ryu"
                    >
                        如靈蛇般優雅前行
                    </div>
                    <LineDot></LineDot>
                </div>
                
                {/* mobile - tablet text */}
                <div className={`absolute xs:hidden bottom-[16vh] left-[30vw] z-[100] flex flex-col items-center justify-center ${lineDone ? 'animate-fadeIn' : ''}`}
                    style={{
                        opacity: 0,
                        animationDelay: '.6s'
                    }}
                >
                    <div className="text-white title-mobile tracking-[4px] gen-ryu"
                    >
                        八角昇往四方四時
                    </div>
                    <div className="text-white title-mobile tracking-[4px] gen-ryu"
                    >
                        由文化孕育出
                    </div>
                    <div className="text-white title-mobile tracking-[4px] gen-ryu">
                        <span style = {{letterSpacing: "5px"}}>BVLGARI</span> 靈感美學
                    </div>
                </div>

                <div className="absolute w-full top-0 left-0 h-[120vh] z-[10] "
                    style = {{
                        background: 'linear-gradient(to top,  transparent, #014407 15%)',

                    }}
                >
                </div>
                
                {/*serpenti star*/}
                <motion.div className="star-container relative top-[-60vh] left-0 z-[10] h-[160vh]"
                style={{
                    opacity: 1,
                    // opacity: star_opacity,
                    overflow: 'hidden',
                    overflowX: "hidden"
                }}>
                   
                    <motion.div className={`absolute top-[60vh]`}
                        animate = {{scale: keep_scaling ? 4 : 0}}
                        transition={{duration: 2.5}}
                        style = {{
                            willChange: "transform",
                            // transform: keep_scaling ? "scale(4)" : "scale(0)", 
                            transformOrigin: "left"
                        }}
                    >
                        <img src = {require("../assets/lines1newcompress.png")} className="h-[100vh]"></img>
                    </motion.div> 

                    <motion.div className={`absolute top-[60vh]`}
                        animate = {{scale: keep_scaling ? 4 : 0}}
                        transition={{duration: 2.5, delay: .3}}
                        style = {{
                            willChange: "transform",
                            // transform: keep_scaling ? "scale(4)" : "scale(0)", 
                            transformOrigin: "left"
                        }}
                    >
                        <img src = {require("../assets/lines2compress.png")} className="h-[100vh]"></img>
                    </motion.div>
                 
                    <motion.div className={`absolute left-0 top-[60vh] z-[100] ${keep_scaling ? 'star-zoom' : ''}`}
                        style={{
                            scale: 0,
                            transformOrigin: "left",
                            transition: '.8s',
                        }}
                    >
                        <img src = {require("../assets/left_star.png")} className="h-[100vh]"></img>
                    </motion.div>
                  
                    {/* desktop */}
                    <motion.div className={`hidden xs:flex flex-col items-end p-10 sm:pt-0  justify-center sm:justify-center z-20 absolute top-[61vh] xs:top-[60vh] w-screen h-screen ${lineDone ? 'animate-fadeIn' : ''}`}
                    style={{
                        paddingRight: '10vw',
                        opacity: 0,
                        animationDelay: '1s'
                        
                    }}>
                        <div className="flex flex-col items-center">
                            <div className="text-white text-[15px] sm:text-[20px] tracking-[5px] xl:tracking-[15px] gen-ryu"
                            >
                                二千年古羅馬文化
                            </div>
                            <div className="text-white text-[15px] sm:text-[20px] tracking-[5px] xl:tracking-[15px] gen-ryu"
                            >
                                如靈蛇般優雅前行
                            </div>

                            <LineDot></LineDot>
                          
                            <div className="text-white text-[15px] sm:text-[20px] flex tracking-[5px] xl:tracking-[15px] sm:mt-0 gen-ryu"
                            >
                                八角昇往四方四時
                            </div>
                            <div className="text-white text-[15px] sm:text-[20px] tracking-[5px] xl:tracking-[15px] gen-ryu"
                            >
                                由文化孕育出
                            </div>
                            <div className="text-white text-[15px] sm:text-[20px] tracking-[5px] xl:tracking-[15px] gen-ryu">
                                <span style = {{letterSpacing: "5px"}}>BVLGARI</span> 靈感美學
                            </div>
                        </div>
                    </motion.div>
                    

                </motion.div>
                
                <motion.div className="absolute w-screen top-0 z-[1000] flex items-end justify-center pb-[15px]"
                    style = {{
                        height: getDeviceViewportHeight()
                    }}
                    initial = {{opacity: 0}}
                    animate = {{opacity: lineDone ? 1 : 0}}
                    transition={{duration: 1, delay: 1.5}}
                >
                    <DownArrow></DownArrow>
                </motion.div>

            </motion.div>

        </div>
    )
}

export default SerpentiNewSection;
