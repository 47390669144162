export const getDeviceViewportHeight = () => {
    const userAgent = navigator.userAgent.toLowerCase();
  
    // Check if the device is Android or iOS
    const isAndroid = /android/.test(userAgent);
    const isIOS = /iphone|ipod|ipad/.test(userAgent);
  
    // For Android, return window.innerHeight
    if (isAndroid) {
      return window.innerHeight + 'px';
    }
  
    // For iOS, return '100vh' to avoid issues with the URL bar (when it's not at the top of the page)
    if (isIOS) {
      return '100vh';
    }
  
    // For other devices, return '100vh' as a fallback
    return '100vh';
};