import React, { useEffect, useState, useRef } from "react";
import { useMotionValue, useMotionValueEvent, useScroll, useTransform, useInView, motion, progress, useAnimation } from "framer-motion";
import DownArrow from "./DownArrow";
import WatchesMobile from "./WatchesMobile";
import WatchesDesktop from "./WatchesDesktop";
import { getDeviceViewportHeight } from "../hooks/GetDeviceHeight";
import LineDot from "./LineDot";

const WatchSection = ({_setBlur, _blur, hide_text}) => {

    const star_animation = useAnimation();
    const parent_container = useRef();
    
    const container1 = useRef();
    const container2 = useRef();
    const container3 = useRef();
    const container4 = useRef();
    const container5 = useRef();
    const container6 = useRef();
    const container7 = useRef();

    const container_text = useRef();
    const scale_star_container = useRef();

    const presented_by = useRef();
    const text_footer = useRef();

    const [blur, setBlur] = useState("10px");
    const [done_blur, setDoneBlur] = useState(false);
    const [done_octacore, setDoneOctocore] = useState(false);
    const [text_opacity_done, setTextOpacity] = useState(false);
    const [show_star, setShowStar] = useState(false);
    const [fade_out_bulgari_container, setFadeOutBulgariContainer] = useState(false);
    const [show_footer_text, setShowFooterText] = useState(false);
    const [show_logo, setShowLogo] = useState(false);
    const [mobile, setSetMobile] = useState(false);
    const [scale_star, setScaleStar] = useState(false);
    
    const [main_watch_opacity, setMainWatchOpacity] = useState(false);
    const [watches_opacity, setWatchesOpacity] = useState(false);
    const [watches_parent_opacity, setWatchesParentOpacity] = useState(false);

    const scaled_star = useRef(false);

    const { scrollYProgress: scrollYProgress1 } = useScroll({
        target: parent_container,
        offset: [`start ${400}px`, "end start"]
    });

    const { scrollYProgress: scrollYProgress2 } = useScroll({
        target: container1,
        offset: [`start center`, "end start"]
    });

    const { scrollYProgress: scrollYProgress3 } = useScroll({
        target: container1,
        offset: [`start end`, "start center"]
    });

    const { scrollYProgress: scrollYProgress4 } = useScroll({
        target: container3,
        offset: [`start -${200}px`, "end start"]
    });

    const { scrollYProgress: scrollYProgress5 } = useScroll({
        target: container4,
        offset: [`start center`, "end end"]
    });

    const { scrollYProgress: scrollYProgress6 } = useScroll({
        target: container5,
        offset: [`start center`, "end end"]
    });

    const { scrollYProgress: scrollYProgress7 } = useScroll({
        target: container6,
        offset: [`start end`, "end end"]
    });

    const { scrollYProgress: scrollYProgress8 } = useScroll({
        target: container7,
        offset: [`start end`, "end end"]
    });

    const { scrollYProgress: scrollYProgress9 } = useScroll({
        target: container_text,
        offset: [`start end`, "end end"]
    });

    const { scrollYProgress: scrollYProgress10 } = useScroll({
        target: presented_by,
        offset: [`start end`, "end end"]
    });

    const { scrollYProgress: scrollYProgress11 } = useScroll({
        target: scale_star_container,
        offset: [`start end`, "end end"]
    });

    const { scrollYProgress: scrollYProgress12 } = useScroll({
        target: text_footer,
        offset: [`start end`, "end end"]
    });


    const gradient_opacity = useTransform(scrollYProgress1, [0, .3], [1, 0]);
    const watch_scale = useTransform(scrollYProgress2, [0, .8], [3, 1]);
    const watch_opacity = useTransform(scrollYProgress2, [0, .8], [0, 1]);
    const blurValue = useTransform(scrollYProgress2, [0, 1], ["20px", "0px"]);

    const text_opacity = useTransform(scrollYProgress3, [0, 1], [0, 1]);

    const watch_container_y = useTransform(scrollYProgress4, [0, 1], ["0%", "-100%"]);
    const watch_container_opacity = useTransform(scrollYProgress4, [0, .5], [1, 0]);

    const watches_container_y = useTransform(scrollYProgress4, [0, 1], ["100%", "0%"]);
    const octacore_opacity = useTransform(scrollYProgress5, [0.5, 1], [0, 1]);

    const watch1_opacity = useTransform(scrollYProgress6, [0, 1], [0, 1]);

    //const watch2_opacity = useTransform(scrollYProgress7, [0, 1], [0, 1]);
    //const watch2_translateX = useTransform(scrollYProgress7, [0, 1], ["-100%", "0%"]);

    //const watch3_opacity = useTransform(scrollYProgress8, [0, 1], [0, 1]);
    //const watch3_translateX = useTransform(scrollYProgress8, [0, 1], ["-100%", "0%"]);

    //const watch4_opacity = useTransform(scrollYProgress9, [0, 1], [0, 1]);
    //const watch4_translateX = useTransform(scrollYProgress9, [0, 1], ["-100%", "0%"]);
    
    const watches_parent_container_y = useTransform(scrollYProgress7, [0, 1], ["0%", "-100%"]);
    const watches_parent_container_opacity = useTransform(scrollYProgress7, [0, .5], [1, 0]);

    const text_container_y = useTransform(scrollYProgress7, [0, 1], ["100%", "0%"]);
    const text_container_opacity = useTransform(scrollYProgress7, [0, .8], [0, 1]);
   
    const bg_black_opacity = useTransform(scrollYProgress8, [0, 1], [1, 0]);
    const bg_white_opacity = useTransform(scrollYProgress8, [0, 1], [0, 1]);

    const text_color = useTransform(scrollYProgress8, [0, 1], ["#FFFFFF", "#757575"]);

    const bg_behind_text_opacity = useTransform(scrollYProgress8, [0, 1], [0, 1]);

    const star_timer = useRef();
    
    const star_scale = useTransform(scrollYProgress11, [0, .5], [200, 1]);
    const text_opacity_inside_star = useTransform(scrollYProgress11, [0, 1], [1, 0]);

    const [scroll_to_top, setScrollToTop] = useState(false);

    useEffect(() => {
        if (hide_text) {
            setTextOpacity(false);
            setScaleStar(false);
        }
    }, [hide_text]);

    useMotionValueEvent(scrollYProgress2, "change", (progress) => {
        
        const newBlur = Math.floor(10 - progress * 10);
        setBlur(newBlur+"px");
        // console.log("blur progress", progress)
        if (progress > .95) {
            setDoneBlur(true);
        }
        if (progress > 0 && progress < .8) {
            setDoneBlur(false);
        }
        // console.log("containe 12 progress", progress)
        // watch_container_y.set("50%");
    });

    useMotionValueEvent(scrollYProgress4, "change", (progress) => {
        // console.log("octacore progress", progress)
        if (progress > 0) {
            setMainWatchOpacity(true);
        }else {
            setMainWatchOpacity(false);
        }
        if (progress == 1) {
            setDoneOctocore(true);
        }
        if (progress > 0 && progress < 1) {
            setDoneOctocore(false);
        }
    })

    useMotionValueEvent(scrollYProgress5, "change", (progress) => {
        if (progress > 0) {
            setWatchesOpacity(true);
        }else {
            setWatchesOpacity(false);
        }
        // if (progress == 1) {
        //     setDoneOctocore(true);
        // }
        // if (progress > 0 && progress < 1) {
        //     setDoneOctocore(false);
        // }
    })

    useMotionValueEvent(scrollYProgress7, "change", (progress) => {
        // console.log("text opacity", progress)
         
        if (progress > 0) {
            setWatchesParentOpacity(true);
        }else {
            setWatchesParentOpacity(false);
        }

        if (progress > .7) {
            setTextOpacity(true);
            // setDoneOctocore(true);
        }
        if (progress > 0 && progress < .6) {
            setTextOpacity(false);
            console.log("show start big again");
            // setDoneOctocore(false);
        }
    })

    useMotionValueEvent(scrollYProgress8, "change", (progress) => {
        // star animation
        // console.log("red progress", progress)

        // if (scaled_star.current) {
        //     // scaled_star.current = false;
        //     setScaleStar(false);
        //     setTimeout(() => {
        //         scaled_star.current = false;
        //     }, 500);
        // }

        if (progress <= .8 && scaled_star.current) {
            console.log("falseee");
            scaled_star.current = false;
            setScaleStar(false);
            // setTimeout(() => {
            //     setScaleStar(false);
            // }, 500);
            return;
        }

        if (progress >= .8 && !scaled_star.current) {
            setTimeout(() => {
                // alert("full");
                console.log("entramos aquiii");
                setScaleStar(true);
                scaled_star.current = true;
            }, 500);
        }
        
        // if (progress < 1 && scaled_star.current) {
        //     scaled_star.current = false;
        //     setScaleStar(false);
        // }
        // if (progress > 0 && progress < .6) {
        //     // setScaleStar(false);
        //     console.log("show start big again");
        //     // setShowStar(false);
        //     // star_timer.current = setTimeout(() => {
        //     //     star_animation.start({
        //     //         scale: [200, 1],
        //     //         transition: {
        //     //             duration: 3
        //     //         }
        //     //     })
        //     // }, 100);
        //     // setTextOpacity(false);
        //     // setDoneOctocore(false);
        // }
    })

    useMotionValueEvent(scrollYProgress9, "change", (progress) => {
        // console.log("container texttttt", progress);
        if (progress == 1) {
            // console.log("set true animationnn");
            setShowFooterText(true);
        }
        if (progress > 0) {
            // setShowStar(true);
        }
        if (progress < .6) {
            // console.log("set falseeee");
            setShowFooterText(false);
            // setShowStar(false);
            // setTextOpacity(false);
            // setDoneOctocore(false);
        }
    })

    useMotionValueEvent(scrollYProgress10, "change", (progress) => {
        // console.log("presented byyy", progress);
        if (Math.round(progress) == 1) {
            setFadeOutBulgariContainer(false);
            setDoneBlur(false);
            setScrollToTop(true);
            _setBlur(true);
            return;
        }
        if (progress <= .8) {
            setScrollToTop(false);
            setFadeOutBulgariContainer(true);
            setDoneBlur(true);
            _setBlur(false);
        }
    })

    useMotionValueEvent(scrollYProgress11, "change", (progress) => {
        if (Math.round(progress) == 1) {
            setFadeOutBulgariContainer(true);
        }
        if (progress < .6) {
            setFadeOutBulgariContainer(false);
        }
    })

    useMotionValueEvent(scrollYProgress12, "change", (progress) => {
        // console.log("text footer", progress)
        if (Math.round(progress) == 1) {
            // setFadeOutBulgariContainer(true);
        }
        if (progress < .6) {
            // setFadeOutBulgariContainer(false);
        }
    })

    const bulgari_container_color = useTransform(scrollYProgress8, [0, 1], ['rgba(255, 255, 255, 0)', '#757575']);
    
    const opacity_bulgari_container = useTransform(scrollYProgress12, [0, .5], [1, 0]);
    const footer_text_opacity = useTransform(scrollYProgress12, [.5, 1], [0, 1]);

    const footer_text_container_opacity = useTransform(scrollYProgress10, [0, .5], [1, 0]);
    const presented_logo = useTransform(scrollYProgress10, [.3, 1], [0, 1]);

    const runStarAnimation = () => {
        
    }

    const updateScreen = () => {
        if (window.innerWidth <= 768) { // Define breakpoint for mobile
            setSetMobile(true);
        } else {
            setSetMobile(false);
        }
    };

    useEffect(() => {
        // Initial check on component mount
        updateScreen();

        // Update margin on window resize
        window.addEventListener('resize', updateScreen);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', updateScreen);
    }, []);

    useEffect(() => {
        // Check the screen size when the component mounts
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        setSetMobile(mediaQuery.matches);
     
        // Event listener for screen size changes
        const handleMediaChange = (e) => {
            setSetMobile(mediaQuery.matches);
        };
    
        // Add listener for resizing
        mediaQuery.addEventListener('change', handleMediaChange);
    
        // Clean up listener on unmount
        return () => mediaQuery.removeEventListener('change', handleMediaChange);
    }, []);

    const handleScrollToTop = () => {
        console.log("here");
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Add smooth scrolling
        });
    };

    return (
        <div className="relative">
          
            <motion.div className="w-screen bg-bulgari-gray relative"
            >   
                <div className="w-screen h-screen sticky top-0"
                    style={{
                        // backgroundImage: `url(${require("../assets/watch_bg.png")})`,
                        // backgroundPosition: "center center",
                        // backgroundRepeat: "no-repeat",
                        // backgroundSize: "cover",
                    }}
                    ref={parent_container}
                >

                    <div className="relative w-full h-full bg-bulgari-gray">

                        <img src = {require("../assets/watch_bgcompress.png")} className="absolute w-screen h-screen top-0 object-cover"></img>
                       
                        {/* watch */}
                        <motion.div className="w-full h-full z-[1000] flex flex-col items-center justify-center absolute overflow-hidden"
                            style={{
                                // translateY: watch_container_y,
                                // opacity: watch_container_opacity,
                                opacity: 1
                            }}
                            initial = {{opacity: 1}}
                            animate = {{opacity: main_watch_opacity ? 0 : 1}}
                            transition={{
                                duration: .3
                            }}
                        >
                            <motion.img src = {require("../assets/bulgari_watch3_optimized.png")} className="object-contain ml-[0px] max-h-[55%] sm:max-h-[80%] xl:max-h-[70%] 2xl:max-h-[80%] mb-5"
                                style={{
                                    scale: watch_scale,
                                    opacity: watch_opacity,
                                    // filter: blurValue.to((blur) => `blur(${blur}px)`),  // Apply blur transformation
                                    filter: `blur(${blur})`
                                }}
                            >
                            </motion.img>
                            
                            {/* mobile - tablet */}
                            <motion.div className={`flex xs:hidden items-center justify-between flex-col sm:pl-0 sm:pr-0 ${done_blur ? 'animate-fadeIn' : ''}`}
                                style={{
                                    opacity: 0,
                                    animationDuration: ".6s",
                                    animationFillMode: "forwards",
                                    // opacity: text_opacity
                                }}
                            >
                                <div className="title-mobile tracking-[4px] mb-0 gen-ryu text-center">
                                    <span className="">以時間跨越時間  <br></br> 餘韻不絕</span>
                                </div>
                                <LineDot></LineDot>
                            </motion.div>

                            {/* desktop */}
                            <motion.div className={`hidden xs:flex items-center justify-between flex-col sm:pl-0 sm:pr-0 min-h-[60px] ${done_blur ? 'animate-fadeIn' : ''}`}
                                style={{
                                    opacity: 0,
                                    animationDuration: ".6s",
                                    animationFillMode: "forwards",
                                    // opacity: text_opacity
                                }}
                            >
                                <div className="text-white white-header text-[1.2rem] mb-0 gen-ryu text-center">
                                    <span className="block mb-[10px]">以時間跨越時間，餘韻不絕</span>
                                </div>
                                <LineDot></LineDot>
                            </motion.div>

                        </motion.div>
                        
                        {/* down arrow */}
                        {/* <div className={`absolute w-screen z-[1000] bottom-0 right-[12px] ${done_blur ? 'animate-fadeIn' : ''}`}
                            style={{
                                opacity: 0,
                                animationDelay: ".5s",
                                animationFillMode: "forwards"
                            }}
                        >
                            <DownArrow></DownArrow>
                        </div> */}
                        
                        
                        {/* watches */}
                        <motion.div className="w-full h-full z-[1000] flex flex-col items-center justify-center absolute"
                            style={{
                                // translateY: watches_parent_container_y,
                                // opacity: watches_parent_container_opacity,
                                display: "flex", 
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            initial = {{opacity: 1}}
                            animate = {{opacity: watches_parent_opacity ? 0 : 1}}
                            transition={{duration: .3}}
                        >

                            <motion.div className="w-full h-full flex flex-col items-center justify-center relative" 
                                initial = {{opacity: 0}}
                                animate = {{opacity: watches_opacity ? 1 : 0}}
                                transition={{duration: .3, delay: .3}}
                            >
                                <motion.div className="h-[80px] flex items-center flex-col justify-between mt-[50px] sm:mb-20"
                                    style={{
                                        opacity: octacore_opacity,
                                        // translateY: watches_container_y,
                                    }}
                                >
                                    <img src = {require("../assets/star.png")} className="max-w-[30px] mr-[0vw]"></img>
                                    <div className="text-white text-spacing text-center text-[.9rem] sm:text-[1.2rem] times-new">ALUMINIUM & OCTO</div>
                                </motion.div>
                                
                                {
                                    (mobile) ?
                                    <div className="flex flex-1 justify-center items-center">
                                        <WatchesMobile done_octacore={done_octacore}></WatchesMobile>
                                    </div>
                                    :
                                    <WatchesDesktop done_octacore={done_octacore}></WatchesDesktop>
                                } 
                            </motion.div>
                           
                        </motion.div>
                        
                        {/* text inside white bg */}
                        <motion.div className={`w-full h-full absolute z-[1000] ${text_opacity_done ? 'animate-fadeIn' : ''}`}
                            style = {{
                                opacity: 0,
                                animationDuration: ".5s",
                                animationFillMode: "forwards",
                                // translateY: text_container_y,
                                // opacity: text_container_opacity,
                            }}
                        >
                            <motion.div className="relative w-full h-full flex flex-col items-center justify-center"
                                style={{
                                    opacity: 1
                                }}
                            >
                                <motion.div className="w-full h-full absolute"
                                    style = {{
                                        // opacity: bg_behind_text_opacity
                                    }}
                                >
                                    <div className="relative w-full h-full flex justify-center items-center"
                                        style={{
                                            transform: "scale(1)",
                                        }}
                                    >
                                        {/* text inside star */}
                                        <motion.div className={`absolute w-full h-full flex justify-center items-center flex-col`}
                                            style={{
                                                // opacity: text_opacity_inside_star
                                            }}
                                            // initial = {{opacity: 1}}
                                            // animate = {{opacity: scale_star ? 0 : 1}}
                                            // transition={{duration: 1}}
                                        >   
                                            
                                            <motion.div className="text-bulgari-yellow mb-[50px] text-[4.3vw] sm:text-[1.2rem] gen-ryu-bold z-[2]"
                                                initial = {{opacity: 1}}
                                                animate = {{opacity: scale_star ? 0 : 1}}
                                                transition={{duration: 1.2}}
                                            >
                                                穿梭於過去、現在與未來之間。
                                            </motion.div>
                                            
                                            {/* mobile - tablet */}
                                            <motion.div className="block sm:hidden content-mobile text-white text-center gen-ryu-sb z-[2] pl-5 pr-5" style={{color: text_color}}
                                                initial = {{opacity: 1}}
                                                animate = {{opacity: scale_star ? 0 : 1}}
                                                transition={{duration: 1.2}}
                                            >
                                                1975 年，<span className="letter-1-space">BVLGARI </span>腕錶誕生，<br></br>踏足時計行業，後於 2012 年，<br></br><span className="letter-1-space">Octo</span> 系列面世，在 10 年間創下 9 項世界紀錄，<br></br> 錶殼裡的各種細膩，恰如珠寶鑲嵌的繁密。 <br></br><br></br>
                                                腕錶可簡約如 <span className="letter-1-space">Octo</span> 系列，<br></br>亦可豐艷至高級珠寶腕錶系列 ─ <br></br>寶石、鑽石、
                                                碧璽等互相輝映，<br></br>圍繞時間的流動。<br></br><br></br>
                                                最新 <span className="letter-1-space">Octo Roma </span>型號腕錶，<br/>
                                                更焦點搭載兩個、三個或四個音簧，<br/>
                                                採用問錶或自動報時機制，<br/>
                                                與意大利裔瑞士指揮家<span className="letter-1-space"> Lorenzo Viotti </span><br></br>共同創作三全音音程，<br/>
                                                一部鐘錶交響曲就此誕生。<br/><br/>
                                                BVLGARI 邁向 140 週年，<br></br>跨越一個世紀 ─
                                                BVLGARI 以不同系列<br></br>告訴世人從不止步， <br></br>
                                                歷史洪流如何洗刷，以時間跨越時間。
                                            </motion.div> 

                                            {/* desktop */}
                                            <motion.div className="hidden sm:block white-content text-center gen-ryu-sb z-[2] pl-5 pr-5 sm:pl-0 sm:pr-0" style={{color: text_color}}
                                                initial = {{opacity: 1}}
                                                animate = {{opacity: scale_star ? 0 : 1}}
                                                transition={{duration: 1.2}}
                                            >
                                                1975 年，<span className="letter-1-space">BVLGARI </span>腕錶誕生，<br></br>踏足時計行業，後於2012年，<span className="letter-1-space">Octo</span> 系列面世 ， <br></br>
                                                在10年間創下9項世界紀錄，錶殼裡的各種細膩,<br></br>恰如珠寶鑲嵌的繁密。 <br></br><br></br>
                                                腕錶可簡約如 <span className="letter-1-space">Octo</span> 系列，<br></br>亦可豐艷至高級珠寶腕錶系列 ─ 寶石、鑽石、 <br></br>
                                                碧璽等互相輝映，圍繞時間的流動。<br></br><br></br>
                                                最新 <span className="letter-1-space">Octo Roma </span>型號腕錶，<br/>
                                                更焦點搭載兩個、三個或四個音簧，<br/>
                                                採用問錶或自動報時機制，<br/>
                                                與意大利裔瑞士指揮家<span className="letter-1-space"> Lorenzo Viotti </span>共同創作三全音音程，<br/>
                                                一部鐘錶交響曲就此誕生。<br/><br/>
                                                BVLGARI 邁向140週年，<br></br>跨越一個世紀 ─
                                                BVLGARI 以不同系列告訴世人從不止步， <br></br>
                                                歷史洪流如何洗刷，以時間跨越時間。
                                            </motion.div> 
                                            
                                        </motion.div>
                                        
                                        <motion.div className="absolute w-full h-full "
                                        >
                                            <motion.div className="relative w-full h-full flex justify-center items-center overflow-hidden"
                                                style={{
                                                    backgroundColor: bulgari_container_color
                                                }}
                                            >

                                                {/* footer bulgari */}
                                                <motion.div className="w-full h-full flex items-center justify-center absolute"
                                                    style={{
                                                        opacity: opacity_bulgari_container
                                                    }}
                                                >
                                                
                                                    <div className="relative w-full h-full justify-center items-center flex">

                                                        <motion.img className={`object-contain h-[13px] sm:h-[30px]`} src = {require("../assets/bulgari.png")}
                                                            initial = {{opacity: 0}}
                                                            animate = {{opacity: scale_star ? 1 : 0}}
                                                            transition={{duration: scale_star ? .6 : 0, delay:scale_star ? 1.8 : 0}}
                                                        >

                                                        </motion.img>

                                                        <motion.img className='w-[20px] sm:w-[35px] ml-3 mr-3 xs:ml-5 xs:mr-5' src = {require("../assets/white_star.png")}
                                                            style={{
                                                                opacity: bg_behind_text_opacity,
                                                            }}
                                                            initial = {{scale: 200}}
                                                            animate = {{scale: scale_star ? 1 : 200}}
                                                            transition={{duration: scale_star ? 1.6 : .3}}
                                                        >
                                                        </motion.img>
                                                        
                                                        <motion.img className={`object-contain h-[13px] sm:h-[30px]`} src = {require("../assets/140newnewnew.png")}
                                                            initial = {{opacity: 0}}
                                                            animate = {{opacity: scale_star ? 1 : 0}}
                                                            transition={{duration: scale_star ? .6 : 0, delay:scale_star ? 1.8 : 0}}
                                                        >
                                                        </motion.img>
                                                    </div>

                                              
                                                
                                                </motion.div>
                                                
                                                {/* footer text */}
                                                <motion.div    
                                                    style={{
                                                        opacity: footer_text_container_opacity
                                                    }}
                                                    className="absolute "
                                                >
                                                    {/* mobile */}
                                                    <motion.div className="block xs:hidden text-white text-white-spacing text-center gen-ryu-sb"
                                                        style={{
                                                            lineHeight: '2',
                                                            opacity: footer_text_opacity,
                                                        }}
                                                    >
                                                        <div className="text-white title-mobile tracking-[4px] text-center gen-ryu-sb">
                                                            一個成功的城市文化保育<br></br>
                                                            並不只困於所在地域<br></br><br></br>
                                                        </div>
                                                        
                                                        <div className="text-white title-mobile tracking-[4px] text-center gen-ryu-sb">
                                                            <span style={{letterSpacing: '5px'}}>BVLGARI </span>透過對珠寶腕錶工藝<br></br>
                                                            開啟了一場當代文藝復興 <br></br>
                                                            如八角星般通往全世界
                                                        </div>
                                                   
                                                    </motion.div>
                                                    
                                                    {/* desktop */}
                                                    <motion.div className="hidden xs:block text-white text-white-spacing text-center gen-ryu-sb"
                                                        style={{
                                                            lineHeight: '2',
                                                            opacity: footer_text_opacity,
                                                        }}
                                                    >
                                                        一個成功的城市文化保育<br></br>
                                                        並不只困於所在地域<br></br><br></br>

                                                        <span style={{letterSpacing: '5px'}}>BVLGARI </span>透過珠寶腕錶工藝 <br></br>
                                                        開啟了一場當代文藝復興 <br></br>
                                                        如八角星般通往全世界
                                                    </motion.div>

                                                </motion.div>
                                                
                                                {/* presented by */}
                                                <motion.div className='absolute'
                                                    style={{
                                                        // translateY: translateY3,
                                                        opacity: presented_logo
                                                    }}
                                                >
                                                    <img src = {require("../assets/presented.png")} className='max-w-[200px]'></img>        
                                                </motion.div>
                                                
                                                {/* down arrow */}
                                                {/* <div className={`absolute w-screen z-[1000] bottom-0 right-[12px] ${fade_out_bulgari_container ? 'animate-fadeIn' : ''}`}
                                                    style={{
                                                        opacity: 0,
                                                        animationDelay: ".8s",
                                                        animationFillMode: "forwards"
                                                    }}
                                                >
                                                    <DownArrow></DownArrow>
                                                </div> */}
                                                
                                                
                                            </motion.div>

                                        </motion.div>

                                    </div>
                                </motion.div>

                            </motion.div>
                        </motion.div> 
                        
                        <motion.div className="w-full h-[50%] absolute top-[-10px]"
                            style = {{
                                background: "linear-gradient(to top, transparent 0%, black 100%)",
                                opacity: 1
                            }} 
                        >
                        </motion.div>

                        {/* black overlay for visible text */}
                        {/* <motion.div className="w-full h-full bg-black/40 absolute top-0"
                            style={{
                                opacity: 1
                            }}
                        >
                        </motion.div> */}

                        {/* white bg  */}
                        {/* <motion.div className=" w-screen h-full absolute bg-white"
                            style={{
                                opacity: bg_white_opacity
                            }}
                        >
                        </motion.div> */}

                        <motion.div className="absolute top-0 w-full flex items-end justify-center pb-[15px] z-[10000]"
                            style = {{
                                height: getDeviceViewportHeight()
                            }}
                            initial = {{opacity: 0}}
                            animate = {{opacity: done_blur ? 1 : 0}}
                            transition={{duration: .6}}
                        >
                            <DownArrow></DownArrow>
                        </motion.div>
                        
                        {/* <motion.div className="absolute w-screen top-0 flex items-end justify-center pb-[15px] z-[10000]"
                            style = {{
                                height: getDeviceViewportHeight()
                            }}
                            initial = {{opacity: 0}}
                            animate = {{opacity: scroll_to_top ? 1 : 0}}
                            transition={{duration: .3, delay: scroll_to_top ? .6 : 0}}
                        >
                            <div className="flex flex-col items-center"
                                onClick={handleScrollToTop}
                            >
                                <img src = {require("../assets/arrow_up.png")} className="animate-down max-w-[30px]"></img>
                                <motion.div className="text-white text-center !mb-0 product-name times-new mt-[15px] !text-[8px]"
                                    style={{
                                        
                                    }}
                                >
                                    Back To The Top
                                </motion.div>
                            </div>
                        </motion.div> */}

                        {/* <motion.div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 z-[10000]"
                            initial = {{opacity: 1}}
                            animate = {{opacity: done_blur ? 1 : 0}}
                            transition={{duration: .6, delay: .6}}
                        >
                            <DownArrow></DownArrow>
                        </motion.div> */}
                        
                    </div>

                </div>
                   
                <div className="h-screen w-screen"
                    ref={container1}
                    /* watch zoom */
                >
                </div>

                <div className="snap-center snap-stop h-screen w-screen put-again-snap"
                    ref = {container3}
                    /* alumninum octo */
                >
                </div>

                <div className=" h-screen  w-screen "
                    ref={container4}
                >
                </div>
                
                {/* <div className="snap-center snap-stop h-screen  w-screen bg-red-900" */}
                <div className="snap-center snap-stop h-screen w-screen put-again-snap"
                    ref={container5}
                    /* text shown */
                >
                </div>
                   
                {/* <div className=" h-screen w-screen snap-center snap-stop bg-blue-900" */}
                <div className=" h-screen w-screen snap-center snap-stop put-again-snap"
                    ref={container6}
                    /* text reverse color */
                >
                    {/* show text */}
                </div>
                   
                {/* <div className="snap-center snap-stop h-screen  w-screen bg-green-900" */}
                <div className="snap-center snap-stop h-screen w-screen put-again-snap"
                    ref={container7}
                >
                    {/* change color and move */}
                </div>
                
                {/* <div className="snap-center snap-stop h-screen  w-screen bg-red-900" */}
                {/* <div className="snap-center snap-stop h-screen w-screen"
                    ref={scale_star_container}
                >
                </div> */}
                
                {/* <div className="snap-center snap-stop h-screen  w-screen bg-blue-900" */}
                <div className="snap-center snap-stop h-screen  w-screen put-again-snap"
                    ref = {text_footer}
                >
                    {/* text footer */}
                    {/* change to logo */}
                </div>

                <div className="snap-center snap-stop h-screen w-screen put-again-snap"
                    ref = {presented_by}
                >
                    {/* presented */}
                    {/* change to logo */}
                </div>

            </motion.div>
        
        </div>
    )
}

export default WatchSection;
