import Rect, { useEffect, useState, useRef } from "react";
import { useMotionValue, useMotionValueEvent, useScroll, useTransform, useInView, motion } from "framer-motion";
import DownArrow from "./DownArrow";
import TubogasDesktop from "./TubogasDesktop";
import TubogasMobile from "./TubogasMobile";
import { getDeviceViewportHeight } from "../hooks/GetDeviceHeight";

const TubogasNewSection = ({blur}) => {

    const container1 = useRef();
    const container2 = useRef();
    const container3 = useRef();
    const container4 = useRef();
    const container5 = useRef();

    const [productFadeIn, setProductFadeIn] = useState(false);
    const [arrow1, setArrow1] = useState(false);
    const [arrow2, setArrow2] = useState(false);
    const [mobile, setMobile] = useState(false);
    
    const { scrollYProgress: scrollYProgress1 } = useScroll({
        target: container1,
        offset: ["start start", "end start"]
    });

    const { scrollYProgress: scrollYProgress2 } = useScroll({
        target: container2,
        offset: ["start start", "end start"]
    });

    const { scrollYProgress: scrollYProgress3 } = useScroll({
        target: container3,
        // start when the container is 200px above the viewport
        offset: [`start -${200}px`, "end start"]
    });

    const { scrollYProgress: scrollYProgress4 } = useScroll({
        target: container4,
        // start when the container is 200px above the viewport
        offset: [`start start`, "end start"]
    });
    
    const opacity_tubogas = useTransform(scrollYProgress1, [0, 1], [0, 1]);
    
    const opacity_tubo1 = useTransform(scrollYProgress2, [0, 1], [0, 1]);

    const opacity_tubo2 = useTransform(scrollYProgress2, [.33, .66], [0, 1]);
    const translateX_tubo2 = useTransform(scrollYProgress2, [.33, .66], ["-100%", "0%"]);

    const opacity_tubo3 = useTransform(scrollYProgress2, [.66, 1], [0, 1]);
    const translateX_tubo3 = useTransform(scrollYProgress2, [.66, 1], ["-100%", "0%"]);

    const translateY_tubo1 = useTransform(scrollYProgress3, [0, .33], ["0%", `-${window.innerHeight * 2}px`]);
    const translateY_tubo2 = useTransform(scrollYProgress3, [.33, .66], ["0%", `-${window.innerHeight * 2}px`]);
    const translateY_tubo3 = useTransform(scrollYProgress3, [.66, 1], ["0%", `-${window.innerHeight * 2}px`]);

    const opacity_tubo_container = useTransform(scrollYProgress3, [0, 1], [1, 0]);

    const opacity_tubo_text = useTransform(scrollYProgress4, [0.5, 1], [0, 1]);

    useEffect(() => {
        setProductFadeIn(false);
        setArrow1(false);
    }, [blur]);

    useMotionValueEvent(opacity_tubo1, "change", (progress) => {
        if (progress >= 0.9) {
            setProductFadeIn(true);
            setArrow1(true);
        }else {
            setProductFadeIn(false);
            setArrow1(false);
        }
    });
    

    useMotionValueEvent(scrollYProgress4, "change", (progress) => {
        if (progress >= 0.9) {
            setArrow1(false);
        }else {
            setArrow1(true);
        }
    });

    useEffect(() => {
        // Check the screen size when the component mounts
        const mediaQuery = window.matchMedia('(max-width: 576px)');
        setMobile(mediaQuery.matches);
     
        // Event listener for screen size changes
        const handleMediaChange = (e) => {
            setMobile(mediaQuery.matches);
        };
    
        // Add listener for resizing
        mediaQuery.addEventListener('change', handleMediaChange);
    
        // Clean up listener on unmount
        return () => mediaQuery.removeEventListener('change', handleMediaChange);
    }, []);


    return (
        <div className="relative bg-black">
    
            <div className="sticky top-[0vh] h-screen w-screen">
        
                    <video src = {require("../assets/video/tubo.mp4")} loop = {true} muted = {true} autoPlay = {true} playsInline = {true}
                        className="h-screen w-screen top-0 absolute"
                        style={{objectFit: 'cover',
                            zIndex: -2
                        }}
                    >
                    </video>
                    <div className="absolute h-full w-full bg-black/50">
                        
                        <div className="relative w-full h-full">

                            <motion.div className=" w-full absolute flex justify-center items-center bg-black/20 z-[3] flex-col "
                                style={{
                                    opacity: opacity_tubo_container,
                                    height: getDeviceViewportHeight()
                                }}
                            >
                                    
                                <motion.div className="h-[80px] flex items-center flex-col justify-between mb-[50px]"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: productFadeIn ? 1 : 0 }}
                                    transition={{ duration: 1}}
                                    // style={{
                                    //     opacity: opacity_tubogas
                                    // }}
                                >
                                    <img src = {require("../assets/star.png")} className="max-w-[30px]"></img>
                                    <div className="text-white text-spacing text-center !mb-0 text-[1.2rem] times-new">TUBOGAS</div>
                                </motion.div>
                                
                                {
                                    (mobile) ? <TubogasMobile productFadeIn={productFadeIn}></TubogasMobile> :
                                    <TubogasDesktop productFadeIn={productFadeIn}></TubogasDesktop> 
                                }

                            </motion.div>
                            
                            {/* mobile - tablet */}
                            <motion.div className="w-full xs:hidden absolute flex justify-center items-center z-[3] flex-col"
                                style={{
                                    opacity: opacity_tubo_text,
                                    height: getDeviceViewportHeight()
                                }}
                            >
                                <div className="text-bulgari-yellow mb-[50px] title-mobile tracking-[4px] gen-ryu-bold text-center" style={{lineHeight:2}}>
                                    BVLGARI設計靈感<br/>當然與羅馬生活文化息息相關
                                    {/* BVLGARI 設計靈感<br/>當然與羅馬生活文化息息相關 */}
                                </div>
                                <div className="text-white text-center content-mobile gen-ryu-sb">
                                    1920 年羅馬市民所使用的瓦斯輸氣管，<br></br> 孕育啟發出具標誌性 Tubogas 系列，<br></br>呈現出珠寶首飾柔軟質感。<br></br><br></br>
                                 
                                    而全新 Tubogas 系列，<br></br> 融合 BVLGARI 大膽的黃金設計標誌，<br></br> 同時加入玫瑰金元素，<br></br> 向品牌先鋒精神致敬之餘，<br></br>亦再次肯定及昇華黃金永恆主角的地位。
                                    {/* 1920 年羅馬市民所使用的瓦斯輸氣管，<br></br>孕育啟發出具標誌性 <span>Tubogas</span> 系列 ， <br></br>呈現出珠寶首飾柔軟質感。 <br></br><br></br>
                                
                                    而全新 Tubogas 系列，<br></br>更融合 BVLGARI 大膽的黃金設計標誌 ， <br></br>
                                    向品牌先鋒精神致敬之餘，<br></br>亦再次肯定黃金永恆主角的地位。 */}

                                </div>
                            </motion.div>

                            {/* desktop */}
                            <motion.div className="w-full hidden xs:absolute xs:flex justify-center items-center z-[3] flex-col"
                                style={{
                                    opacity: opacity_tubo_text,
                                    height: getDeviceViewportHeight()
                                }}
                            >
                                <div className="text-bulgari-yellow mb-5 text-[1.2rem] gen-ryu-bold text-center" style={{lineHeight:2}}>
                                    BVLGARI設計靈感<br/>當然與羅馬生活文化息息相關
                                    {/* BVLGARI 設計靈感<br/>當然與羅馬生活文化息息相關 */}
                                </div>
                                <div className="text-white text-center white-content gen-ryu-sb pl-5 pr-5 sm:pr-0 sm:pl-0">
                                    1920 年羅馬市民所使用的瓦斯輸氣管，<br></br> 孕育啟發出具標誌性 Tubogas 系列，呈現出珠寶首飾柔軟質感。<br></br><br></br>
                                    
                                    而全新 Tubogas 系列，<br></br> 融合 BVLGARI 大膽的黃金設計標誌，<br></br> 同時加入玫瑰金元素，<br></br> 向品牌先鋒精神致敬之餘，亦再次肯定及昇華黃金永恆主角的地位。
                            
                                    {/* 1920 年羅馬市民所使用的瓦斯輸氣管， <br></br>
                                    孕育啟發出具標誌性 <span>Tubogas</span> 系列 ， 呈現出珠寶首飾柔軟質感。 <br></br><br></br>
                                
                                    而全新 Tubogas 系列，<br></br>更融合 BVLGARI 大膽的黃金設計標誌 ， <br></br>
                                    向品牌先鋒精神致敬之餘，亦再次肯定黃金永恆主角的地位。 */}

                                </div>
                            </motion.div>
                                                        
                            <motion.div className="absolute w-screen top-0 z-[1000] flex items-end justify-center pb-[15px] h-full"
                                style = {{
                                }}
                                initial = {{opacity: 0}}
                                animate = {{opacity: productFadeIn ? 1 : 0}}
                                transition={{duration: .6}}
                            >
                                <DownArrow></DownArrow>
                            </motion.div>
                            
                        </div>

                            
                    </div>
                        
                        
            </div>

            <motion.div  className=" h-[100vh]"
                ref={container1}
                /* Tubogas logo */
            >  
            </motion.div >

            <motion.div  className="h-[100vh] "
                ref={container2}
                /* Tubogas logo */
            >  
            </motion.div >
                
            <motion.div  className="snap-stop snap-center h-[100vh] put-again-snap"
                ref={container3}
                /* holding screen */
            >  
            </motion.div >


            <motion.div  className=" h-[100vh] "
                ref={container4}
                /* buvgari design concept text */
            >  
            </motion.div >

            <motion.div  className="snap-stop snap-center  h-[100vh] put-again-snap"
                ref={container5}
            >  
            </motion.div >
        
        </div>
        
     
    )
}

export default TubogasNewSection;
