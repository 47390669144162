import React from "react";
import { useMotionValue, useMotionValueEvent, useScroll, useTransform, useInView, motion, useAnimation } from "framer-motion";
import DownArrow from "./DownArrow";
import LineDot from "./LineDot";

const ItemsDesktop = ({showProduct, serpenti_text}) => {

    return (
        <div className={`absolute top-[50vh] flex w-screen items-center justify-center z-10 ${showProduct ? 'animate-fadeIn' : ''}`} id="products"
            ref={serpenti_text}
            style={{opacity: 0}}
        >
            <div className="w-screen lg:container flex justify-around items-baseline relative ">
                
                <motion.div className="flex flex-col items-center"
                    style={{
                        
                    }}
                >
                    <motion.img src={require("../assets/item1.png")} className="w-[13vw]"
                        style={{
                        }}
                    ></motion.img>
                    <div className="min-h-[50px] w-full flex flex-col justify-between items-center mt-4">
                        {/* <img src = {require("../assets/dots.png")} className="object-contain max-w-[100px] sm:max-w-[150px]"></img> */}
                        <LineDot></LineDot>
                        <div className="text-white text-center !mb-0 product-name times-new">
                            Serpenti Viper Ring
                        </div>
                    </div>
                </motion.div>
                
                <motion.div className="flex flex-col items-center"
                    style={{
                        
                    }}
                >
                    <motion.img src={require("../assets/item2.png")} className="w-[23vw]"
                        style={{
                            // translateX: transform_item2
                        }}
                    ></motion.img>
                    <div className="min-h-[50px] w-full flex flex-col justify-between items-center mt-4">
                        {/* <img src = {require("../assets/dots.png")} className="object-contain max-w-[100px] sm:max-w-[150px]"></img> */}
                        <LineDot></LineDot>
                        <div className="text-white text-center !mb-0 product-name times-new">
                            Serpenti Sugarloaf Shoulder Bag
                        </div>
                    </div>
                </motion.div>
            
                <motion.div className="flex flex-col items-center"
                    style = {{
                        
                    }}
                >
                    <motion.img src={require("../assets/item3test2.png")} className="w-[15vw]"
                        style={{
                            // translateX: transform_item3
                        }}
                    ></motion.img>
                    <div className="min-h-[50px] w-full flex flex-col justify-between items-center mt-4">
                        {/* <img src = {require("../assets/dots.png")} className="object-contain max-w-[100px] sm:max-w-[150px]"></img> */}
                        <LineDot></LineDot>
                        <div className="text-white text-center !mb-0 product-name times-new">
                            Aeterna Serpenti Anima
                        </div>
                    </div>
                </motion.div>
                    
                <motion.div className="flex flex-col items-center"
                    style={{
                        
                    }}
                >
                    <motion.img src={require("../assets/item4.png")} className="w-[14.5vw]"
                        style={{
                        }}
                    >
                    </motion.img>
                    <div className="min-h-[50px] w-full flex flex-col justify-between items-center mt-4">
                        {/* <img src = {require("../assets/dots.png")} className="object-contain max-w-[100px] sm:max-w-[150px]"></img> */}
                        <LineDot></LineDot>
                        <div className="text-white text-center !mb-0 product-name times-new">
                            Serpenti Tubogas Bracelet
                        </div>
                    </div>
                </motion.div> 
                
                <motion.div className="absolute w-screen z-[1000] mt-[-50vh] flex justify-end items-center "
                    style={{
                        height: 100+'vh'
                    }}
                    initial = {{opacity: 0}}
                    animate = {{opacity: showProduct ? 1 : 0}}
                    transition={{duration: .6}}
                >
                    <div className="relative w-full h-full flex items-center justify-center">
                        <div className="absolute bottom-5">
                            <DownArrow></DownArrow>
                        </div>
                    </div>
                </motion.div>

            </div>

        </div> 
    )
}

export default ItemsDesktop;
