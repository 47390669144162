import Rect, { useEffect, useState, useRef, Suspense } from "react";
import { useMotionValue, useMotionValueEvent, useScroll, useTransform, useInView, motion } from "framer-motion";
import Coin3D from './Coin3D';
import DownArrow from "./DownArrow";
import { getDeviceViewportHeight } from "../hooks/GetDeviceHeight";
import LineDot from "./LineDot";
import DivasNewSection from "./DivasNewSection";
import DivasTest from "./DivasTest";

const MoneteNewSection = ({blur}) => {

    const totalImages = 13;
    
    const container1 = useRef();
    const show_text_after_bubble = useRef();
    const showed_text_after_bubble = useRef(false);
    const ring_green_container = useRef();
    const monete_extra_text_container = useRef();
    const monete_title_video_container = useRef();
    const bulgari_ring_container = useRef();
    const hotel_container = useRef();
    const separation_first_step_container = useRef();
    const separation_second_step_container = useRef();
    const step3_container_separation = useRef();
    const move_video_step3_container = useRef();
    const first_monete_text_container = useRef();
    const second_monete_text_container = useRef();
    const first_text_step2_container = useRef();
    const second_text_step2_container = useRef();
    const step3_ring_container = useRef();
    const step3_ring_container_continue = useRef();
    const step3_ring_text = useRef();
    const bubbles_container = useRef();
    const move_bubles = useRef();
    const step3_moving_ref = useRef();

    const fadeout_bubbles_container = useRef();
    const fadeout_divas_container = useRef();

    const container2 = useRef();
    const container3 = useRef();
    const container4 = useRef();
    const container5 = useRef();
    const container6 = useRef();
    const container7 = useRef();

    const [showfull, setShowFull] = useState(false);
    const [ring_scale, setRingScale] = useState(false);

    const [fade_out_step1, setFadeOutStep1] = useState(false);
    const [fade_out_video, setFadeOutVideo] = useState(false);
    const [fade_out_hotel, setFadeOutHotel] = useState(false);

    const [animate, setAnimate] = useState(false);
    const [bubbleStart, setBubbleStart] = useState(false);
    const [bubbleDone, setBubbleDone] = useState(false);
    const [show_arrow, setShowArrow] = useState(false);
    const [show_arrow2, setShowArrow2] = useState(false);

    const scroll_divas = useMotionValue(0);

    const king_timer = useRef();

    const monete_more_text = useRef();

    const video_ref_king = useRef();
    const video_ref_bulgari = useRef();

    const [bulgari_video_done, setBulgariVideoDone] = useState(false);
    const [kings_video_done, setKingsVideoDone] = useState(false);
    const [hotel_done, setHotelDone] = useState(false);

    const [coliseum_video, setColiseumVideo] = useState(require("../assets/video/coliseum_3.mp4"));
    const [king_video, setKingVideo] = useState(require("../assets/video/kings.mp4"));

    const [bubles_animation, setBubblesAnimation] = useState(false);
    const [show_bubble_text, setShowBubblesText] = useState(false);

    const [divas_opacity, setDivasOpacity] = useState(false);
    const [fade_out_bubbles, setFadeOutBubbles] = useState(false);
    const [fade_out_divas, setFadeOutDivas] = useState(false);
    const [auto_show_girls, setAutoShowGirls] = useState(false);

    const updateScreen = () => {
        if (window.innerWidth <= 576) { // Define breakpoint for mobile
            setColiseumVideo(require("../assets/video/coliseum_trim_mobile.mp4"));
            setKingVideo(require("../assets/video/king_mobile.mp4"));
        } else {
            setColiseumVideo(require("../assets/video/coliseum_trim.mp4"));
            setKingVideo(require("../assets/video/kings.mp4"));
        }
    };

    useEffect(() => {
        if (blur) {
            setBubblesAnimation(false);
        }
    }, [blur]);

    const first_render = useRef(true);

    const containerRef = useRef(null);
    const [scrolling, setScrolling] = useState(false);

    const handleScroll = (e) => {
        if (!scrolling) {
            setScrolling(true);

            const scrollSpeed = 1000; // Adjust this value for slower or faster scrolling
            const direction = e.deltaY > 0 ? 1 : -1;

            const currentScroll = containerRef.current.scrollTop;
            containerRef.current.scrollTo({
                top: currentScroll + scrollSpeed * direction,
                behavior: "smooth",
            });

            setTimeout(() => {
                setScrolling(false);
            }, 10000); // Throttle scroll events
        }
    };

    useEffect(() => {
        // Initial check on component mount
        updateScreen();

        // Update margin on window resize
        window.addEventListener('resize', updateScreen);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', updateScreen);
    }, []);

    useEffect(() => {
        // Check the screen size when the component mounts
        const mediaQuery = window.matchMedia('(max-width: 576px)');
        
        if (mediaQuery.matches) {
            setColiseumVideo(require("../assets/video/coliseum_trim_mobile.mp4"));
            setKingVideo(require("../assets/video/king_mobile.mp4"));
        }
      
    }, []);

    useEffect(() => {   

        const video = video_ref_bulgari.current;
    
        const handleVideoEnd = () => {
            setBulgariVideoDone(true);
            setTimeout(() => {
                setShowArrow2(true);
            }, 800);
            // You can run any additional logic here
        };
        
        const handleLoadedData = () => {
            video.pause();
        };

        // Attach the 'ended' event listener
        video.addEventListener('ended', handleVideoEnd);
        video.addEventListener('loadeddata', handleLoadedData);

        // Clean up the event listener on unmount
        return () => {
            video.removeEventListener('ended', handleVideoEnd);
            video.addEventListener('loadeddata', handleLoadedData);
        };

    }, []);

    useEffect(() => {   
        const video = video_ref_king.current;
    
        const handleVideoEnd = () => {
            // console.log('Video has finished playing kings');
           
            if(kings_video_done) {
                // console.log("finish no more");
                return;
            }
           
            // if (!kings_video_done) {
            //     console.log("here finish king")
            //     setKingsVideoDone(false);
            //     video_ref_king.current.currentTime = 0;
            //     video_ref_king.current.pause();
            //     return;
            // }
           
            // console.log("video done", animate);

            if (!animate) {
                // console.log("coin not animated yet so no video");
                return;
            }

            setKingsVideoDone(true);
            setTimeout(() => {
                setShowArrow(true);
            }, 800);
            //   setBulgariVideoDone(true);
          // You can run any additional logic here
        };
    
        // Attach the 'ended' event listener
        video.addEventListener('ended', handleVideoEnd);
    
        // Clean up the event listener on unmount
        return () => {
          video.removeEventListener('ended', handleVideoEnd);
        };

    }, [animate]);
      
    const { scrollYProgress: scrollYProgress1 } = useScroll({
        target: container1,
        offset: ["start end", "end end"]
    });

    const { scrollYProgress: scrollYProgress2 } = useScroll({
        target: container2,
        offset: [`start end`, "end end"]
    });

    const { scrollYProgress: scrollYProgress3 } = useScroll({
        target: container3,
        // start when its above 200px of viewport
        offset: [`start -${200}px`, "end start"]
    });

    const { scrollYProgress: scrollYProgress4 } = useScroll({
        target: container4,
        offset: [`start start`, "end start"]
    });

    const { scrollYProgress: scrollYProgress5 } = useScroll({
        target: container5,
        offset: [`start start`, "end start"]
    });

    const { scrollYProgress: scrollYProgress6 } = useScroll({
        target: container6,
        offset: [`start start`, "end start"]
    });

    const { scrollYProgress: scrollYProgress7 } = useScroll({
        target: container7,
        // starts when its above 200px of viewport
        offset: [`start -${200}px`, "end start"]
    });
    
    const { scrollYProgress: scrollYProgress8 } = useScroll({
        target: monete_more_text,
        // starts when its above 200px of viewport
        offset: [`start start`, "end start"]
    });

    const { scrollYProgress: scrollYProgress9 } = useScroll({
        target: ring_green_container,
        // starts when its above 200px of viewport
        offset: [`start end`, "end end"]
    });

    const { scrollYProgress: scrollYProgress10 } = useScroll({
        target: monete_extra_text_container,
        // starts when its above 200px of viewport
        offset: [`start end`, "end end"]
    });

    const { scrollYProgress: scrollYProgress11 } = useScroll({
        target: monete_title_video_container,
        // starts when its above 200px of viewport
        offset: [`start end`, "end end"]
    });

    const { scrollYProgress: scrollYProgress12 } = useScroll({
        target: bulgari_ring_container,
        offset: [`start end`, "end end"]
    });

    const { scrollYProgress: scrollYProgress13 } = useScroll({
        target: first_monete_text_container,
        // starts when its above 200px of viewport
        offset: [`start start`, "end start"]
    });

    const { scrollYProgress: scrollYProgress14 } = useScroll({
        target: second_monete_text_container,
        // starts when its above 200px of viewport
        offset: [`start start`, "end start"]
    });

    const { scrollYProgress: scrollYProgress15 } = useScroll({
        target: separation_first_step_container,
        // starts when its above 200px of viewport
        offset: [`start start`, "end start"]
    });

    const { scrollYProgress: scrollYProgress16 } = useScroll({
        target: first_text_step2_container,
        // starts when its above 200px of viewport
        offset: [`start start`, "end start"]
    });

    const { scrollYProgress: scrollYProgress17 } = useScroll({
        target: second_text_step2_container,
        // starts when its above 200px of viewport
        offset: [`start start`, "end start"]
    });

    const { scrollYProgress: scrollYProgress18 } = useScroll({
        target: separation_second_step_container,
        // starts when its above 200px of viewport
        offset: [`start start`, "end start"]
    });

    const { scrollYProgress: scrollYProgress19 } = useScroll({
        target: step3_container_separation,
        // starts when its above 200px of viewport
        offset: [`start start`, "end start"]
    });

    const { scrollYProgress: scrollYProgress20 } = useScroll({
        target: move_video_step3_container,
        // starts when its above 200px of viewport
        offset: [`start start`, "end start"]
    });

    const { scrollYProgress: scrollYProgress21 } = useScroll({
        target: step3_ring_container,
        // starts when its above 200px of viewport
        offset: [`start start`, "end start"]
    });

    const { scrollYProgress: scrollYProgress22 } = useScroll({
        target: step3_ring_text,
        // starts when its above 200px of viewport
        offset: [`start start`, "end start"]
    });

    const { scrollYProgress: scrollYProgress23 } = useScroll({
        target: step3_moving_ref,
        // starts when its above 200px of viewport
        offset: [`start start`, "end start"]
    });

    const { scrollYProgress: scrollYProgress24 } = useScroll({
        target: bubbles_container,
        // starts when its above 200px of viewport
        offset: [`start end`, "start start"]
    });

    const { scrollYProgress: scrollYProgress25 } = useScroll({
        target: step3_ring_container_continue,
        // starts when its above 200px of viewport
        offset: [`start start`, "end start"]
    });

    const { scrollYProgress: scrollYProgress26 } = useScroll({
        target: hotel_container,
        // starts when its above 200px of viewport
        offset: [`start end`, "end end"]
    });

    // show text after buble
    const { scrollYProgress: scrollYProgress27 } = useScroll({
        target: show_text_after_bubble,
        // starts when its above 200px of viewport
        offset: [`start end`, "end end"]
    });


    // for bubles container animation yellow
    const { scrollYProgress: scrollYProgress28 } = useScroll({
        target: move_bubles,
        // starts when its above 200px of viewport
        offset: [`start end`, "end end"]
    });

    // fadeout bubbles container
    const { scrollYProgress: scrollYProgress29 } = useScroll({
        target: fadeout_bubbles_container,
        // starts when its above 200px of viewport
        offset: [`start end`, "end end"]
    });

    // fadeout divas container
    const { scrollYProgress: scrollYProgress30 } = useScroll({
        target: fadeout_divas_container,
        // starts when its above 200px of viewport
        offset: [`start end`, "end end"]
    });

    useMotionValueEvent(scrollYProgress30, "change", (progress) => {
        scroll_divas.set(progress);
        
        // for fadeout triangle
        if (progress >= .5) {   
            setFadeOutDivas(true);
        }else {
            setFadeOutDivas(false);
        }

        // for fadein divas image
        if (Math.round(progress) >= 1) {
            // alert("1");
            setAutoShowGirls(true);
            // setTriangleIndex(1);
            // clearInterval(triangle_timer.current);
        } else if (progress > 0 && progress < 0.5) {
            // alert("no 1");
            setAutoShowGirls(false);
        } else if (progress === 0) {
            // alert("0");
            // autoChangeTriangle();
        }

    });

    useMotionValueEvent(scrollYProgress29, "change", (progress) => {
      
        if (progress >= .5) {
            setFadeOutBubbles(true);
        }else {
            setFadeOutBubbles(false);
        }
 
    });

    useMotionValueEvent(scrollYProgress28, "change", (progress) => {
       
        if (progress < .8) {
            setDivasOpacity(false);
        }

        if (progress >= .5) {
            setBubblesAnimation(true);
        }
        if (progress <= 0.04) {
            setBubblesAnimation(false);
        }
        
    });

    useMotionValueEvent(scrollYProgress27, "change", (progress) => {
        // console.log("CONTAINER OF TEXT MONETEEEEEE PROGRESSSS", progress);
        if (progress == 0) {
            // console.log("CONTAINER RED FOR TEXT TO BOTTOM AGAIN");
            setTimeout(() => {
                setBubbleStart(false);
                window.scrollBy({
                    top: -window.innerHeight,
                    behavior: "smooth",
                })
            }, 600);
        }else {
            // console.log("CONTAINER FOR TEXT FAR TOP VIEWPORT");
        }
    });

    useMotionValueEvent(scrollYProgress12, "change", (progress) => {
        // console.log("bulgari video");

        // console.log("progress bulgari", progress);
        
        setShowArrow2(false);

        if (progress > 0) {
            setFadeOutHotel(true);
        }else {
            setFadeOutHotel(false);
        }

        if (Math.round(progress) == 1) {
            
            if (progress < 1 && Math.floor(video_ref_bulgari.current.currentTime) > 0) {
                // console.log("reutrning to hoteellll");
                return;
            }

            if (Math.round(progress) == 1 && Math.floor(video_ref_bulgari.current.currentTime) > 0) {
                // console.log("let it play", progress, video_ref_bulgari.currentTime);
                return;
            }

            console.log("playyyy", Math.floor(video_ref_bulgari.current.currentTime));
            setBulgariVideoDone(false);
            video_ref_bulgari.current.currentTime = 0;
            video_ref_bulgari.current.play();

        }

        if (progress == 0) {
            console.log("enter herere");
            setShowArrow2(true);
            setHotelDone(true);
            video_ref_bulgari.current.currentTime = 0;
            video_ref_bulgari.current.pause();
            setBulgariVideoDone(false);
        }
   
    });

    useMotionValueEvent(scrollYProgress11, "change", (progress) => {
        // setShowArrow(false);
        // console.log("rede containerrrrrr", progress);
        // alert("progresss" + progress)
        // console.log("progresss", progress);

        if (progress > 0) {
            setFadeOutStep1(true);
        }else {
            setFadeOutVideo(false);
            setFadeOutStep1(false);
            setKingsVideoDone(false);
            video_ref_king.current.currentTime = 0;
            video_ref_king.current.pause();
        }

        if (Math.round(progress) == 1) {
            console.log("herrere kings");
            setFadeOutVideo(true);
            clearTimeout(king_timer.current);
            king_timer.current = setTimeout(() => {
                video_ref_king.current.play();
            }, 300);
        }

        if (progress <= .8) {
            console.log("regresando a anilloooo");
            clearTimeout(king_timer.current);
            setKingsVideoDone(false);
            video_ref_king.current.currentTime = 0;
            video_ref_king.current.pause();
        }

        if (progress == 0) {
            // console.log("herrere kings 2");
            // setFadeOutVideo(false);
            // setKingsVideoDone(false);
            // video_ref_king.current.currentTime = 0;
            // video_ref_king.current.pause();
        }
        // if (progress > 0) {
        //     setFadeOutStep1(true);
        //     setFadeOutVideo(true);
        // }

        // if (progress == 1) {
        //     console.log("play video");
        //     video_ref_king.current.play();
        // }else {
        //     setFadeOutVideo(false);
        //     setFadeOutStep1(false);
        //     setKingsVideoDone(false);
        //     video_ref_king.current.currentTime = 0;
        //     video_ref_king.current.pause();
        // }


        // used to play monete video
        // if (progress == 1) {
        //     setKingsVideoDone(false);
        //     video_ref_king.current.currentTime = 0;
        //     video_ref_king.current.play();
        // }else {
        //     setKingsVideoDone(false);
        //     video_ref_king.current.currentTime = 0;
        // }

    });

    useMotionValueEvent(scrollYProgress10, "change", (progress) => {

        // use for the text above monete video
        if (progress > 0) {
            // video_ref_king.current.currentTime = 5;
        }
        if (progress == 0) {
            setKingsVideoDone(false);
            setTimeout(() => {
                video_ref_king.current.currentTime = .5;
                video_ref_king.current.play();
            }, 300);
        }
    });

    useMotionValueEvent(scrollYProgress2, "change", (progress) => {
        // video_ref_king.current.currentTime = progress * 5;
    });

    useMotionValueEvent(scrollYProgress3, "change", (progress) => {
        // console.log("progress green monete", progress);
    });

    useMotionValueEvent(scrollYProgress4, "change", (progress) => {
    });

    useMotionValueEvent(scrollYProgress5, "change", (progress) => {
        // const newIndex = Math.floor(progress * (ring_images.length - 1));
        // setRingImage(newIndex);
        // video_ref.current.currentTime = progress * 4;
    });

    useMotionValueEvent(scrollYProgress8, "change", (progress) => {
        // console.log("more texttt", progress);
        // video_ref.current.currentTime = progress * 2;
    });

    useMotionValueEvent(scrollYProgress9, "change", (progress) => {
        // console.log("ring containereee", progress);
    });

    useMotionValueEvent(scrollYProgress16, "change", (progress) => {
        // console.log("first text step 2");
        // video_ref.current.currentTime = progress * 2;
    });

    useMotionValueEvent(scrollYProgress17, "change", (progress) => {
        // console.log("second text step 2");
        // video_ref.current.currentTime = progress * 2;
    });

    useMotionValueEvent(scrollYProgress21, "change", (progress) => {
        // console.log("second text step 2");
        // const newIndex = Math.floor(progress * (ring_images.length - 1));
        // console.log("new indexxx", newIndex);
        // setRingImage(ring_images[newIndex]);
    });

    useMotionValueEvent(scrollYProgress24, "change", (progress) => {

        console.log("progress buble and bubble start", progress, bubbleStart);

        if (progress == 1) {
            console.log("auto scroll after blue");
            // setTimeout(() => {
            //     // console.log("enter here");
            //     // show_text_after_bubble.current.scrollIntoView({
            //     //     behavior: "smooth",
            //     //     block: "end"
            //     // });
            //     window.scrollBy({
            //         top: window.innerHeight,
            //         behavior: "smooth",
            //     })
            //     showed_text_after_bubble.current = true;
            // }, 600);
        }

        if (progress < 1 && showed_text_after_bubble.current) {
            console.log("REGRESANDOOOO")
            setBubbleStart(false);
            return;
        }

        if(progress>0) {
            console.log("here buble 1");
            setBubbleStart(true)       
        } else {
            console.log("here buble 2");
            setBubbleStart(false);
            showed_text_after_bubble.current = false; 
        }
    });

    useMotionValueEvent(scrollYProgress26, "change", (progress) => {
        // console.log("hotelll", progress);
       
        if (Math.round(progress) == 1) {
            setTimeout(() => {
                // console.log("pause vidoe")
                setKingsVideoDone(false);
                video_ref_king.current.currentTime = 0;
                video_ref_king.current.pause();
            }, 300);
            setHotelDone(true);
        }else {
            // console.log("hotel down herererer");
            setKingsVideoDone(false);
            setTimeout(() => {
                video_ref_king.current.play();
            }, 300);
            setHotelDone(false);
            setShowArrow2(false);
        }

        // if (progress == 1) {
        //     // video_ref_king.current.currentTime = 0;
        //     setTimeout(() => {
        //         console.log("pause vidoe")
        //         setKingsVideoDone(false);
        //         video_ref_king.current.currentTime = 0;
        //         video_ref_king.current.pause();
        //     }, 300);
        //     setHotelDone(true);
        //     setShowArrow2(true);
        // }else {
        //     setKingsVideoDone(false);
        //     video_ref_king.current.play();
        //     setHotelDone(false);
        //     setShowArrow2(false);
        // }
      
    });

    useMotionValueEvent(scrollYProgress21, "change", (progress) => {
    });

    useMotionValueEvent(scrollYProgress1, "change", (progress) => {
        // console.log("container blueeee", progress);
        /*const newIndex = Math.ceil(progress * 47);
        setCoind3dIndex(newIndex);*/
        
        if(animate==false && Math.round(progress) > 0) {
            setAnimate(true);
        }
        if(progress==0) {
            setAnimate(false);
            setRingScale(false);
        }
    });
    
    const opacity_video_monete = useTransform(scrollYProgress8, [0, 1], [1, 0]);
    const opacity_monete_text = useTransform(scrollYProgress8, [0, 1], [0, 1]);

    const gradient_opacity = useTransform(scrollYProgress1, [0, 1], [1, 0]);
    const rotateY = useTransform(scrollYProgress1, [0, 1], ["0deg", "360deg"]);
    
    const coin_opacity = useTransform(scrollYProgress1, [0, 1], [1, 0]);

    const monete_text_opacity = useTransform(scrollYProgress1, [0, 1], [0, 1]);

    const monete_title_color = useTransform(scrollYProgress8, [0, 1],["#757575", "#FFFFFF"]);

    const step1_opacity = useTransform(scrollYProgress3, [0, 1], [1, 0]);
    const step2_opacity = useTransform(scrollYProgress3, [0, 1], [0, 1]);

    const video_opacity = useTransform(scrollYProgress4, [0, 1], [0, 1]);
   
    const ring_opacity = useTransform(scrollYProgress5, [.8, 1], [0, 1]);

    const ring_container_opacity = useTransform(scrollYProgress6, [0, 1], [1, 0]);
    
    const ring_text_opacity = useTransform(scrollYProgress7, [0, 1], [0, 1]);
    
    const ring_green_scale = useTransform(scrollYProgress9, [0, 1], [5, 1]);
    const ring_green_opacity = useTransform(scrollYProgress9, [0, 0.1], [0, 1]);

    const porpotion_scale = 730/window.innerHeight*0.48;
    const porpotion_scale_ring = 730/window.innerHeight*.95;
    const porpotion_translate = 730/window.innerHeight*-15 + 'px';

    const coin_scale = useTransform(scrollYProgress9, [0, 1], [1, porpotion_scale]);
    
    const coin_translate = useTransform(scrollYProgress9, [0, 1], ['0px', porpotion_translate]);

    const coin_opacity_monete = useTransform(scrollYProgress10, [0, 1], [1, 0]);
    

    const step1_opacity_monete = useTransform(scrollYProgress11, [0, 1], [1, 0]);
   
    const monete_title_opacity = useTransform(scrollYProgress11, [0, 1], [0,1]);
    const video_opacity_monete = useTransform(scrollYProgress11, [0, 1], [0, 1]);
    const video_parent_monete_opacity = useTransform(scrollYProgress12, [0, 1], [1, 0]);

    const monete_background_after_video_opacity = useTransform(scrollYProgress12, [0, 1], [0, 1]);


    const first_monete_text_opacity = useTransform(scrollYProgress13, [0, .5], [0, 1]);
    const second_monete_text_opacity = useTransform(scrollYProgress14, [0, .5], [0, 1]);

    const first_step_container_opacity = useTransform(scrollYProgress15, [0, 1], [1, 0]);
    const second_step_container_opacity = useTransform(scrollYProgress15, [0, 1], [0, 1]);

    const first_text_step2_opacity = useTransform(scrollYProgress16, [0, 1], [0, 1]);
    const second_text_step2_opacity = useTransform(scrollYProgress17, [0, 1], [0, 1]);

    const second_step_parent_container_opacity = useTransform(scrollYProgress18, [0, 1], [1, 0]);

    const step3_parent_container_opacity = useTransform(scrollYProgress19, [0, 1], [0, 1]);
    const step3_ring_opacity = useTransform(scrollYProgress20, [0, 1], [0, 1]);
    
    const step3_ring_container_opacity = useTransform(scrollYProgress22, [0, 1], [1, 0]);

    const step3_ring_text_opacity = useTransform(scrollYProgress22, [0, 1], [0, 1]);

    // new
    const monete_coin_opacity = useTransform(scrollYProgress11, [0, .5], [1, 0]);
    const monete_title_opacity_star = useTransform(scrollYProgress11, [.5, 1], [0, 1]);

    const first_step_opacity = useTransform(scrollYProgress26, [0, .5], [1, 0]);

    
    useMotionValueEvent(ring_green_scale, "change", (progress) => {
        if (progress <= 1.5) {
            setShowFull(true);
            setShowArrow(true);
        }else {
            setShowFull(false);
            setShowArrow(false);
        }
        // console.log("green rinfg conainereee");
        // video_ref.current.currentTime = progress * 2;
    });

    const move = () => {
        setRingScale(true);
    }   

    const bubleText = () => {

        if (first_render.current) {
            first_render.current = false;
            return;
        }

        if (show_bubble_text) {
            // console.log("aqui2");
            setShowBubblesText(false);
            return;
        }
        // console.log("aqui");
        setShowBubblesText(true);
        setDivasOpacity(true);
    }

    return (        
        <div className="relative z-[1000]">
        
            <motion.div className="sticky top-0 w-screen h-screen z-[2]">
        
                {/* step 1 */}
                <motion.div className="absolute top-0 w-screen h-screen z-[3]"
                    style={{
                        // backgroundImage: `url(${require("../assets/marmoldark_withoutpeople.jpg")})`,
                        backgroundImage: `url(${require("../assets/marmoldark_withoutpeople.jpg")})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        opacity: first_step_opacity,
                    }}
                >
                    <div className="relative w-screen h-screen">
           
                        <img src = {require("../assets/marmoldark_withoutpeoplecompress.jpg")} className="absolute w-screen h-screen top-0 object-cover"></img>
                    
                        <motion.div className="w-full h-[100px] absolute top-[-1px] z-[5]"
                            style = {{
                                // background: 'linear-gradient(to bottom, black, transparent)',
                                background: "linear-gradient(to top, transparent 0%, black 100%)",
                            }}
                        >
                        </motion.div> 
                 
                        <motion.div className="absolute w-screen h-screen flex justify-center items-center"
                            initial = {{opacity: 1}}
                            animate = {{opacity: fade_out_step1 ? 0 : 1}}
                            transition={{duration: .6}}
                            id = "bulgari-coin"
                        >
                     
                            <motion.div 
                                className="absolute w-screen h-screen"
                                initial={{ scale: 1 }}
                                animate={{ scale: ring_scale ? porpotion_scale : 1, translateY: ring_scale ? porpotion_translate : 0}}
                                transition={{ duration: .6}}
                            >
                                {
                                    blur ? null :
                                    <Coin3D id="coin3d" className="absolute left-0 top-0 z-[100]" 
                                        onComplete = {(completed) => {
                                            setTimeout(() => {
                                                move()
                                            }, 300)
                                        }}
                                        animate={animate}
                                        blur = {blur}
                                    /> 
                                }
                            </motion.div>
                        
                        </motion.div>
                        
                        {/* {Coin combo} */}
                        <div className="absolute flex w-screen h-screen justify-center items-center overflow-hidden top-0 left-0">
                            
                            <motion.div className="relative flex justify-center items-center"
                                initial = {{opacity: 1}}
                                animate = {{opacity: fade_out_step1 ? 0 : 1}}
                                transition={{duration: .6}}
                            >

                                {/* desktop */}
                                <div className={`hidden xl:flex flex-col absolute text-white text-[1.2rem] items-end text-right ${ring_scale ? 'animate-fadeIn' : ''}`}
                                    style={{
                                        opacity: 0,
                                        animationDuration: "1s",
                                        animationFillMode: "forwards",
                                        fontWeight: 400,
                                        animationDelay: ".8s",
                                        transform: 'translateX(-400px)',
                                        letterSpacing: 2,
                                        width: '300px'
                                    }}
                                >
                                    帝王古幣 隨時代流轉
                                    <img src = {require("../assets/dots.png")} className="object-contain max-w-[150px] mt-5 mb-3"></img>
                                </div>
                                
                                <motion.div class="relative" 
                                    style={{
                                        width: '400px',
                                        height: '400px',
                                        transformOrigin: "center"
                                    }}
                                >
                                    
                                    {/* green ring */}
                                    <motion.img src = {require("../assets/anillo.png")} className="absolute inset-0 m-auto w-[300px] sm:w-[100%]"
                                        initial={{ opacity: 0, scale: 5 }}
                                        animate={{ opacity: ring_scale ? 1 : 0, scale: ring_scale ? 1 : 5 }}
                                        transition={{ duration: .6}}
                                    ></motion.img>

                                    <div className="absolute inset-0 m-auto  flex items-center justify-center">
                                        <div className="relative ">
                                            
                                            {/* text for mobile */}
                                            <div className={`absolute xl:hidden bottom-[105%] flex-col flex items-center w-full ${ring_scale ? 'animate-fadeIn' : ''}`}
                                                style={{
                                                    opacity: 0,
                                                    animationDuration: "1s",
                                                    animationDelay: ".8s",
                                                    animationFillMode: "forwards",
                                                }}
                                            >
                                                <div className={`text-white title-mobile tracking-[4px] items-end text-right !mb-0 gen-ryu`}
                                                    style={{
                                                        animationDuration: "1s",
                                                        animationFillMode: "forwards",
                                                        fontWeight: 400,
                                                        fontSize: '18px',
                                                    }}
                                                >
                                                    帝王古幣 隨時代流轉 
                                                </div>
                                                
                                                <LineDot></LineDot>
                                             
                                                <div className={`text-white title-mobile tracking-[4px] text-left !mb-0 gen-ryu`}
                                                    style={{
                                                        animationDuration: "1s",
                                                        animationDelay: ".8s",
                                                        animationFillMode: "forwards",
                                                        fontWeight: 400,
                                                        fontSize: '18px',
                                                    }}
                                                >
                                                    從歷史富物 演變成藝術永恆
                                                </div>
                                        
                                            </div>
                                            
                                            {/* full coin to cover */}
                                            <motion.img src = {require("../assets/fullcoin.png")} className={`w-[300px] sm:w-[100%] ${ring_scale ? 'animate-fadeIn' : ''}`}
                                                style={{
                                                    opacity: 0,
                                                    animationDelay: ".5s",
                                                    animationFillMode: "forwards"
                                                }}
                                            ></motion.img> 

                                            {/* text for mobile */}
                                            <div className="absolute xl:hidden w-full bottom-[-40px] flex items-center justify-center">
                                                <div className={`text-center product-name w-100 title-mobile text-white !mb-0 ${ring_scale ? 'animate-fadeIn' : ''}`}
                                                    style={{
                                                        opacity: 0,
                                                        animationDuration: "1s",
                                                        animationDelay: ".8s",
                                                        animationFillMode: "forwards",
                                                        fontSize: '18px',
                                                    }}
                                                >
                                                    Augustus Emerald Monete Sautoir 
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    
                                    {/* desktop */}
                                    <div className={`hidden xl:block xl:absolute text-center product-name w-100 text-white text-[1.2rem] ${ring_scale ? 'animate-fadeIn' : ''}`}
                                        style={{
                                            opacity: 0,
                                            animationDuration: "1s",
                                            animationFillMode: "forwards",
                                            animationDelay: ".8s",
                                            fontSize: '18px',
                                            top: '420px',
                                            width: '400px',
                                        }}
                                    >
                                        Augustus Emerald Monete Sautoir
                                    </div>

                                </motion.div>

                                {/* desktop  */}
                                <div className={`hidden xl:flex flex-col absolute text-white text-[1.2rem] text-left ${ring_scale ? 'animate-fadeIn' : ''}`}
                                    style={{
                                        opacity: 0,
                                        animationDuration: "1s",
                                        animationFillMode: "forwards",
                                        animationDelay: ".8s",
                                        fontWeight: 400,
                                        transform: 'translateX(400px)',
                                        letterSpacing: 2,
                                        width: '300px'
                                    }}
                                >
                                    從歷史富物 演變成藝術永恆
                                    <img src = {require("../assets/dots.png")} className="object-contain max-w-[150px] mt-5 mb-3"></img>
                                </div>

                            </motion.div>
                            
                            <motion.div className="absolute w-screen top-0 flex items-end justify-center pb-[15px]"
                                style = {{
                                    height: getDeviceViewportHeight()
                                }}
                                initial = {{opacity: 0}}
                                animate = {{opacity: ring_scale ? 1 : 0}}
                                transition={{duration: .6, delay: .9}}
                            >
                                <DownArrow></DownArrow>
                            </motion.div>

                        </div>
                        
                        <motion.div className="absolute w-screen h-screen flex justify-center items-center flex-col"
                            style={{
                                // backgroundImage: `url(${require("../assets/marmoldark.png")})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                // opacity: 1
                                // opacity: monete_title_opacity
                            }}
                        >
                            <div className="absolute w-full flex flex-col h-[70%] justify-center z-[6]">

                                <motion.div className="h-[80px] flex items-center flex-col justify-between mb-[50px]"
                                    initial = {{opacity: 0}}
                                    animate = {{opacity: fade_out_video ? 1 : 0}}
                                    transition={{duration: .6}}
                                >
                                    <img src = {require("../assets/star.png")} className="max-w-[30px]"></img>
                                    <motion.div className="text-white text-spacing text-center !mb-0 text-[1.2rem] times-new"
                                        style={{
                                            // color: monete_title_color
                                        }}
                                    >
                                        MONETE
                                    </motion.div>
                                </motion.div>
                                
                                {/* mobile - tablet text */}
                                <div className="block xs:hidden ">

                                    <motion.div className={`flex flex-col items-center justify-between ${kings_video_done ? 'animate-fadeIn' : ''}`}
                                        style={{
                                            opacity: 0,
                                            animationDuration: ".6s",
                                            animationFillMode: "forwards"
                                            // opacity: opacity_monete_text
                                            // opacity: 0
                                            // opacity: monete_text1_opacity
                                        }}
                                    >
                                        <div className="">
                                      
                                           <div className="text-white title-mobile tracking-[4px] gen-ryu text-center">
                                                保留不同君主古鑄幣原貌
                                            </div>
                                            <div className="text-white title-mobile tracking-[4px] gen-ryu text-center">
                                                輪轉中 窺探羅馬文化輪廓
                                            </div>

                                            <LineDot></LineDot>
                            
                                            <div className="text-white text-center content-mobile gen-ryu-sb">
                                                <span className="letter-1-space">BVLGARI Monete Augusto Imperator<br></br></span> 頂級玫瑰金古幣頸鍊，<br></br>
                                                在方形切割的鑽石結構中心鑲嵌了一枚<br></br>西元前 27 年到西元 14 年的古董銀幣。<br></br><br></br>
                                                幣上刻有羅馬皇帝<span className="letter-1-space"> Augustus </span><br></br>的肖像；而羅馬 <span className="letter-1-space">BVLGARI Hotel <br></br> </span>便是位處於以紀念這開國君主來命名的<br></br><span className="letter-1-space"> Piazza Augusto Imperatore </span>廣場上。
                                            </div>
                                        </div>
                                        
                                    </motion.div>
                                
                                </div>

                                {/* desktop text */}
                                <div className="hidden xs:block">

                                    <motion.div className={`flex flex-col items-center justify-between ${kings_video_done ? 'animate-fadeIn' : ''}`}
                                        style={{
                                            opacity: 0,
                                            animationDuration: ".6s",
                                            animationFillMode: "forwards"
                                            // opacity: opacity_monete_text
                                            // opacity: 0
                                            // opacity: monete_text1_opacity
                                        }}
                                    >
                                        <div>
                                            <div className="text-white sm:text-[25px] white-header gen-ryu !tracking-[10px] sm:tracking-[18px]">
                                                保留不同君主古鑄幣原貌
                                            </div>
                                            <div className="text-white sm:text-[25px] white-header gen-ryu !tracking-[10px] sm:tracking-[18px]">
                                                輪轉中 窺探羅馬文化輪廓
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-center">
                                            <LineDot></LineDot>
                                            {/* <img src = {require("../assets/dots.png")} className="object-contain max-w-[150px] mt-5"></img> */}
                                        </div>
                                    </motion.div>
                            
                                    <motion.div className={`pl-5 pr-3 sm:pl-0 sm:pr-0 flex items-center flex-col justify-center ${kings_video_done ? 'animate-fadeIn' : ''}`}
                                        style={{
                                            // opacity: 0
                                            // opacity: monete_text2_opacity
                                            // marginTop: '-6rem'
                                            opacity: 0,
                                            animationDuration: ".6s",
                                            animationFillMode: "forwards"
                                        }}
                                    >
                                        <div className="text-white text-center m-0 white-content gen-ryu-sb">
                                            <span className="letter-1-space">BVLGARI Monete Augusto Imperatore</span> 頂級玫瑰金古幣頸鍊，<br></br>
                                            在方形切割的鑽石結構中心
                                            鑲嵌了一枚西元前27年到西元14年的古董銀幣,<br></br>
                                            幣上便刻有羅馬皇帝<span className="letter-1-space"> Augustus </span>的肖像；<br></br>而羅馬 <span className="letter-1-space">BVLGARI Hotel </span>更是位處於<br></br>
                                            以紀念這開國君主來命名的<span className="letter-1-space"> Piazza Augusto Imperatore </span>廣場上。
                                        </div>
                                    </motion.div>
                                
                                </div>
                                
                            </div>
                            
                            <motion.div className="relative w-full h-full"
                                style={{
                                    // opacity: video_parent_monete_opacity,
                                    // opacity: .3
                                }}
                            >
                                <motion.video src = {king_video} className="w-full h-full object-cover"
                                    autoPlay = {false}
                                    muted = {true}
                                    loop = {false}
                                    ref={video_ref_king}
                                    playsInline = {true}
                                    preload = {true}
                                    initial = {{opacity: 0}}
                                    animate = {{opacity: fade_out_video ? 1 : 0}}
                                    transition={{duration: .6, delay: .3}}
                                >
                                </motion.video>
                                
                                <motion.div className="absolute w-screen top-0 flex items-end justify-center pb-[15px]"
                                    style = {{
                                        height: getDeviceViewportHeight()
                                    }}
                                    initial = {{opacity: 0}}
                                    animate = {{opacity: kings_video_done ? 1 : 0}}
                                    transition={{duration: .6}}
                                >
                                    <DownArrow></DownArrow>
                                </motion.div>
                                
                            </motion.div>

                        </motion.div>

                    </div>
                    
                </motion.div>
            
                {/* step 2 */}
                <motion.div className="absolute top-0 w-screen h-screen z-[2] bg-transparent"
                    initial = {{opacity: 1}}
                    animate = {{opacity: fade_out_hotel ? 0 : 1}}
                    transition={{duration: .6}}
                >
                    <motion.div className="w-screen h-full flex flex-col items-center justify-center mt-[-1px]"
                        style={{
                            backgroundImage: `url(${require("../assets/colicompress.png")})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        }}
                    >
                  
                        {/* cl code <div className="max-w-[80%]" style={{marginTop: '25vh'}}> */}
                        
                        {/* mobile - tablet */}
                        <div className="block sm:hidden max-w-[100%] z-[1] pl-10 pr-10 " style={{marginTop: '0vh'}}>
                            <motion.div className={`flex flex-col justify-between items-center ${hotel_done ? 'animate-fadeIn' : ''}`}
                                style={{
                                    opacity: 0,
                                    animationDuration: ".6s",
                                    animationFillMode: "forwards"
                                    // opacity: hotel_text1_opacity
                                }}
                            >
                                <div className="text-white text-center m-0 title-mobile tracking-[4px] gen-ryu" style = {{}}>
                                    從珠寶工藝 <br></br>
                                    走到酒店建築空間體驗 <br></br>
                                    多元體驗<span> Dolce Vita </span> <br></br>文化之美
                                </div>
                            </motion.div>

                            <motion.div className={`flex items-center justify-center ${hotel_done ? 'animate-fadeIn' : ''}`}
                                style={{
                                    opacity: 0,
                                    animationDuration: ".6s",
                                    animationFillMode: "forwards"
                                    // opacity: hotel_text2_opacity
                                }}
                            >
                                <LineDot></LineDot>
                            </motion.div>

                            <motion.div className = {`text-white text-center content-mobile gen-ryu-sb ${hotel_done ? 'animate-fadeIn' : ''}`}
                                style = {{
                                    // opacity: hotel_text2_opacity
                                    opacity: 0,
                                    animationDuration: ".6s",
                                    animationFillMode: "forwards"
                                }}
                            >
                                Dolce Vita 美好年代，<br></br>由<span className="letter-1-space"> BVLGARI </span>從珠寶工藝至酒店透過建築，<br></br>從羅馬出發，面向世界，<br></br>讓各地也可窺探古羅馬的奢華時代。<br></br><br></br> 自 2004 年起，首間<span className="letter-1-space"> BVLGARI </span>酒店於印尼巴里島開幕，接著米蘭、北京、<br></br>倫敦、杜拜及東京等相繼建成。<br></br><br></br>
                            </motion.div>
                            <motion.div className={`text-white text-center content-mobile gen-ryu-sb ${hotel_done ? 'animate-fadeIn' : ''}`}
                                style = {{
                                    // opacity: hotel_text2_opacity
                                    opacity: 0,
                                    animationDuration: ".6s",
                                    animationFillMode: "forwards"
                                }}
                            >
                                不受語言溝通所限，<br></br><span className="letter-1-space">BVLGARI </span>酒店透過建築、彩色工藝，<br></br>致敬奧古斯都時代的文化之美。<br></br><br></br>
                                羅馬文化不限於珠寶體現，<br></br>而是更具完整性地拓展至世界各地。
                            </motion.div>
                        </div>
                        
                        {/* desktop */}
                        <div className="hidden sm:block max-w-[80%] p-5 z-[1]" style={{marginTop: '0vh'}}>
                            <motion.div className={`flex flex-col justify-between items-center ${hotel_done ? 'animate-fadeIn' : ''}`}
                                style={{
                                    opacity: 0,
                                    animationDuration: ".6s",
                                    animationFillMode: "forwards"
                                    // opacity: hotel_text1_opacity
                                }}
                            >
                                <div className="text-white text-center m-0 white-header gen-ryu" style = {{}}>
                                    從珠寶工藝 走到酒店建築空間體驗
                                </div>
                                <div className="text-white text-center m-0 white-header gen-ryu" style = {{}}>
                                    多元體驗<span style={{letterSpacing:'5px'}}> Dolce Vita </span>文化之美
                                </div>
                            </motion.div>

                            <motion.div className={`flex items-center justify-center ${hotel_done ? 'animate-fadeIn' : ''}`}
                                style={{
                                    opacity: 0,
                                    animationDuration: "1s",
                                    animationFillMode: "forwards"
                                    // opacity: hotel_text2_opacity
                                }}>
                                    <img src = {require("../assets/dots.png")} className="object-contain max-w-[150px] mt-5"></img>
                            </motion.div>
                            <motion.div className = {`text-white text-center white-content gen-ryu-sb ${hotel_done ? 'animate-fadeIn' : ''}`}
                                style = {{
                                    marginTop: "25px",
                                    // opacity: hotel_text2_opacity
                                    opacity: 0,
                                    animationDuration: ".6s",
                                    animationFillMode: "forwards"
                                }}
                            >
                                Dolce Vita 美好年代，由<span className="letter-1-space"> BVLGARI </span>從珠寶工藝至酒店透過建築，<br></br>從羅馬出發，面向世界，<br></br>讓各地也可窺探古羅馬的奢華時代。<br></br> 自 2004 年起，首間<span className="letter-1-space"> BVLGARI </span>酒店於印尼巴里島開幕，<br></br> 接著米蘭、北京、倫敦、杜拜及東京等相繼建成。
                            </motion.div>
                            <motion.div className={`text-white text-center white-content gen-ryu-sb ${hotel_done ? 'animate-fadeIn' : ''}`}
                                style = {{
                                    // opacity: hotel_text2_opacity
                                    opacity: 0,
                                    animationDuration: ".6s",
                                    animationFillMode: "forwards"
                                }}
                            >
                                不受語言溝通所限，<br></br><span className="letter-1-space">BVLGARI </span>酒店透過建築、彩色工藝，<br></br>致敬奧古斯都時代的文化之美，<br></br>
                                奢華體驗，羅馬文化不限於珠寶體現，<br></br>而是更具完整性地拓展至世界各地。
                            </motion.div>
                        </div>
                        
                        <motion.div className="absolute w-screen top-0 flex items-end justify-center pb-[15px]"
                            style = {{
                                height: getDeviceViewportHeight()
                            }}
                            initial = {{opacity: 0}}
                            animate = {{opacity: hotel_done ? 1 : 0}}
                            transition={{duration: .6, delay: .3}}
                        >
                            <DownArrow></DownArrow>
                        </motion.div>

                    </motion.div>
            
                </motion.div> 
                
                {/* step 3 */}
                <div className="w-full h-screen absolute top-0 z-[1]">

                    <div className="relative w-full h-full flex items-center justify-center mt-[-1px] xs:mt-[0px]">

                        {/* mobile - tablet */}
                        <div className="absolute xs:hidden w-full h-full z-[2] top-0 flex justify-center items-center flex-col xs:pl-0 xs:pr-0">
                            <div>
                                <div className={`text-white-margin-bottom text-white text-center m-0 title-content tracking-[4px] gen-ryu ${bulgari_video_done ? 'animate-fadeIn' : ''}`} 
                                    style = {{
                                        opacity: 0, animationDuration: ".6s", animationFillMode: "forwards", opacity: 0
                                    }}
                                >
                                    螺旋曲線 屹立不倒
                                </div>
                                <div className={` text-white text-center m-0 title-content tracking-[4px] gen-ryu ${bulgari_video_done ? 'animate-fadeIn' : ''}`} 
                                    style = {{
                                        opacity: 0, animationDuration: ".6s", animationFillMode: "forwards", opacity: 0
                                    }}
                                >
                                    縈繞後世你我指上 
                                </div>
                            </div>
                            <div className={`flex items-center justify-center ${bulgari_video_done ? 'animate-fadeIn' : ''}`}
                                style={{
                                    opacity: 0, animationDuration: ".6s", animationFillMode: "forwards", opacity: 0
                                }}
                            >
                                <LineDot></LineDot>
                            </div>
                            <div className={`${bulgari_video_done ? 'animate-fadeIn' : ''}`}
                                style={{
                                    opacity: 0, animationDuration: ".6s", animationFillMode: "forwards",
                                }}
                            >
                                <div className="content-mobile text-center text-white gen-ryu-sb">
                                    走過 Piazza Augusto Imperatore 廣場，<br></br>來到擁有二千年歷史的羅馬競技場。<br></br><br></br>
                                    BVLGARI B.ZERO1 取其環狀建築結構作指環，<br></br>螺旋曲線見證羅馬文明經典標誌，<br></br>
                                    經典之所以謂之經典，時間去蕪存菁，<br></br>極其代表性的羅馬歷史，縈繞後世你我的指上。
                                </div>
                            </div>
                        
                        </div>
                        
                        {/* desktop */}
                        <div className="hidden xs:flex absolute w-full h-full z-[2] top-0 justify-center items-center flex-col pl-5 pr-5 xs:pl-0 xs:pr-0">
                            <div>
                                <div className = {`white-header text-[20px] text-center gen-ryu ${bulgari_video_done ? 'animate-fadeIn' : ''}`}
                                    style = {{letterSpacing: "10px", animationDuration: ".6s", animationFillMode: "forwards", opacity: 0}}
                                >
                                    螺旋曲線 屹立不倒
                                </div>
                                <div className={`white-header text-[20px] text-center gen-ryu ${bulgari_video_done ? 'animate-fadeIn' : ''}`}
                                    style = {{letterSpacing: "10px", opacity: 0, animationDuration: ".6s", animationFillMode: "forwards"}}
                                >
                                    縈繞後世你我指上 
                                </div>
                            </div>
                            <div className={`flex items-center justify-center ${bulgari_video_done ? 'animate-fadeIn' : ''}`}
                                style={{
                                    opacity: 0, animationDuration: ".6s", animationFillMode: "forwards", opacity: 0
                                }}
                            >
                                <img src = {require("../assets/dots.png")} className="object-contain max-w-[150px] mt-5"></img>
                            </div>
                            
                            <div className={`${bulgari_video_done ? 'animate-fadeIn' : ''}`}
                                style={{
                                    opacity: 0, animationDuration: ".6s", animationFillMode: "forwards",
                                }}
                            >
                                <div className="white-content text-center white-content gen-ryu-sb" style={{marginTop:'25px'}}>
                                    走過 Piazza Augusto Imperatore 廣場，<br></br>來到擁有二千年歷史的羅馬競技場。
                                </div>
                                <div className="white-content text-center white-content gen-ryu-sb">
                                    BVLGARI B.ZERO1 取其環狀建築結構作指環，<br></br>螺旋曲線見證羅馬文明經典標誌，
                                </div>
                                <div className="white-content text-center white-content gen-ryu-sb">
                                    經典之所以謂之經典，時間去蕪存菁，<br></br>極其代表性的羅馬歷史，     縈繞後世你我的指上。
                                </div>       
                            </div>
                        
                        </div>
                        
                        <motion.div className="absolute w-screen top-0 flex items-end justify-center pb-[15px] z-[2]"
                            style = {{
                                height: getDeviceViewportHeight()
                            }}
                            initial = {{opacity: 0}}
                            animate = {{opacity: bulgari_video_done ? 1 : 0}}
                            transition={{duration: .6, delay: .3}}
                        >
                            <DownArrow></DownArrow>
                        </motion.div>

                        <motion.video src = {coliseum_video} className="w-full h-full object-cover"
                            autoPlay = {true}
                            muted = {true}
                            loop = {false}
                            ref = {video_ref_bulgari}
                            playsInline = {true}
                            preload = {true}
                        >
                        </motion.video>
                    </div>
                </div>

                {/* DIVAS SECTION BUBBLE */}
                <motion.div
                    className="absolute w-screen h-[150vh] z-[10000] flex flex-col justify-center items-center"
                    initial = {{bottom: '-100vh'}}
                    animate = {{bottom: `${bubles_animation ? '0' : '-150vh'}`}}
                    transition={{duration: 1}}
                    onAnimationComplete={bubleText}
                    style={{
                        // backgroundImage: `url(${require("../assets/yellow_bubbles.png")})`,
                        // backgroundSize: "contain",
                        // backgroundRepeat: "no-repeat",
                    }}
                >
                    <motion.div className="w-full h-full relative flex flex-col items-center justify-center"
                        initial = {{opacity: 1}}
                        animate = {{opacity: fade_out_bubbles ? 0 : 1}}
                        transition={{duration: .3}}
                    >

                        {/* buble image */}
                        <img src = {require("../assets/yellow_bubbles.png")} className="absolute top-0 z-[1]"></img>
                        
                        {/* container color */}
                        <div className="h-[100%] w-full absolute bg-bulgari-yellow top-[30%]">

                        </div>

                        {/* mobile - tablet */}
                        <motion.div className="flex xs:hidden flex-col h-[50%] items-center justify-around z-[2] relative mt-[50vh]"
                        >
                            <div className={`flex items-center justify-center flex-col w-full h-full ${show_bubble_text ? 'animate-fadeIn' : ''}`}
                                style={{
                                    opacity: 0,
                                    animationDuration: ".6s",
                                    animationFillMode: "forwards"
                                }}
                            >
                                <div className={`text-white-margin-bottom text-white text-center m-0 title-mobile tracking-[4px] gen-ryu`}>
                                    一片磚路
                                </div>
                                <div className=" text-white text-center m-0 title-mobile tracking-[4px] gen-ryu">
                                    孕育出四通瑰麗
                                </div>

                                <LineDot></LineDot>

                                <div className="content-mobile text-center text-white gen-ryu-sb">
                                    羅馬卡拉卡拉浴場文化遺產，<br></br>無論地震破壞再大，<br></br>也摧毀不了扇形地磚的光芒。<br></br><br></br>
                                </div>
                        
                                <div className="content-mobile text-center text-white gen-ryu-sb">
                                    <span class="letter-1-space">BVLGARI Divas' Dream </span>系列<br></br>將扇狀馬賽克融入各種高級珠寶首飾， 
                                </div>
                        
                                <div className="content-mobile text-center text-white gen-ryu-sb">
                                    扇狀形框鑲嵌各種彩色寶石，<br></br>隨婉若游龍之姿閃爍、擺動。
                                </div>
                                
                            </div>
                        </motion.div>

                        {/* desktop */}
                        <motion.div className={`hidden xs:flex flex-col items-center justify-between pl-5 pr-5 sm:pl-0 sm:pr-0 z-[2] mt-[50vh] ${show_bubble_text ? 'animate-fadeIn' : ''}`}
                            style={{
                                opacity: 0,
                                animationDuration: ".6s",
                                animationFillMode: "forwards"
                            }}
                        >
                            <div className="flex items-center justify-center flex-col">
                                <div className="white-header text-[20px] mb-0 white-header gen-ryu" >
                                    一片磚路
                                </div>
                                <div className="white-header text-[20px] mb-0 white-header gen-ryu">
                                    孕育出四通瑰麗
                                </div>
                            </div>
                            
                            <LineDot></LineDot>
                        
                            <div className="white-content text-center white-content gen-ryu-sb">
                                羅馬卡拉卡拉浴場文化遺產，無論地震破壞再大，也摧毀不了扇形地磚的光芒。
                            </div>
                            <div className="white-content text-center white-content gen-ryu-sb">
                                <span class="letter-1-space">BVLGARI Divas' Dream </span>系列將扇狀馬賽克融入各種高級珠寶首飾， 
                            </div>
                            <div className="white-content text-center white-content gen-ryu-sb">
                                扇狀形框鑲嵌各種彩色寶石，隨婉若游龍之姿閃爍、擺動。
                            </div>
                        </motion.div>
                        
                        <motion.div className="absolute w-screen bottom-0 flex items-end justify-center pb-[15px] z-[2]"
                            style = {{
                                height: getDeviceViewportHeight()
                            }}
                            initial = {{opacity: 0}}
                            animate = {{opacity: show_bubble_text ? 1 : 0}}
                            transition={{duration: .6, delay: .2}}
                        >
                            <DownArrow></DownArrow>
                        </motion.div>
                        
                    </motion.div>
                    
                </motion.div>
                
                <DivasTest
                    divas_opacity = {divas_opacity}
                    fade_out_bubbles = {fade_out_bubbles}
                    fade_out_divas = {fade_out_divas}
                    scroll_divas = {scroll_divas}
                    auto_show_girls = {auto_show_girls}
                >
                </DivasTest>


            </motion.div>
            

            {/* <div className="snap-center snap-stop h-[100vh] w-screen bg-blue-900" */}
            {/* remove to make coin scroll then zoom out */}
            <div className="snap-center snap-stop h-[100vh] w-screen put-again-snap"
                ref={container1}
            >
                {/* coin */}
            </div>
            
            {/* remove this one since ios hard to move */}
            {/* <div className="snap-center snap-stop h-[100vh] w-screen bg-red-900" */}
            {/* <div className="snap-center snap-stop h-[100vh] w-screen bg-blue-900"
                ref={ring_green_container}
            >
            </div> */}
            
            {/* <div className="snap-center snap-stop h-[100vh] w-screen bg-green-900" */}
            <div className="snap-center snap-stop h-[100vh] w-screen put-again-snap"
                ref = {monete_title_video_container}
            >
                {/* play video 1111 */}
                {/* remove coin opacity and show monete title   */}
            </div>

            {/* <div className="snap-center snap-stop w-screen h-[100vh] bg-purple-900" */}
            {/* <div className="snap-center snap-stop w-screen h-[100vh]" 
                ref={monete_extra_text_container}
            >
                {/* text above video  
            </div>*/}

            {/* <div className="snap-center snap-stop w-screen h-[100vh] bg-blue-900" */}
            <div className="snap-center snap-stop w-screen h-[100vh] put-again-snap"
                // ref = {monete_title_video_container}
                // ref={coin_monete_container}
                ref = {hotel_container}
            >
                {/* hotel */}
                {/* remove all monete video and text and show hotel */}
            </div>

            {/* <div className="snap-center snap-stop w-screen h-screen bg-red-900" */}
            {/* <div className="snap-center snap-stop w-screen h-screen "
                ref={container2}
            >
            </div> */}
         
            {/* <div className="snap-center snap-stop w-screen h-screen bg-blue-900" */}
            <div className="snap-center snap-stop w-screen h-screen put-again-snap"
                ref={bulgari_ring_container}
            >
                {/* bulgari video */}
                {/* remove hotel and show bulgari ring */}
            </div>
            
            {/* <div className="h-[100vh] w-screen relative z-[10000] snap-center snap-stop bg-blue-300"
                ref={bubbles_container}
            >
                {/* <div className="w-full h-full relative"
                    style={{
                        backgroundImage: `url(${require("../assets/yellow_bubbles.png")})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat"
                    }}
                >
                    <div className="w-full h-[100px] bg-bulgari-yellow bottom-[-10px] absolute">
                        
                    </div>
                </div> 
            </div> */}

            {/* MOVE BUBBLES */}
            <div className="snap-center snap-stop h-[100vh] w-screen relative z-[10000] overflow-hidden put-again-snap"
                ref={move_bubles}
            >
            </div>
    
            {/* DIVAS SECTION */}
            
            {/* FADE OUT BUBBLES CONTAINER */}
            <div className="snap-center snap-stop h-[100vh] w-screen relative put-again-snap"
                ref = {fadeout_bubbles_container}
            >
            </div>

            {/* FADE OUT DIVAS DREAM CONTAINER */}
            <div className="snap-center snap-stop h-[100vh] w-screen relative put-again-snap"
                ref = {fadeout_divas_container}
            >
            </div>

        </div>
    )


}

export default MoneteNewSection;
