import React, { useState, useEffect } from "react";
import { useMotionValue, useMotionValueEvent, useScroll, useTransform, useInView, motion } from "framer-motion";
import LineDot from "./LineDot";

const TubogasDesktop = ({productFadeIn}) => {

    return (
        <div className="flex w-screen max-w-[1500px] 2xl:max-w-[1700px] items-center justify-around relative"
            // ref={serpenti_text}
        >
            
            <motion.div className={`max-w-[25%] ${productFadeIn?'animate-fadeIn':''} `}
                style={{
                    opacity: 0 //opacity_tubo1,
                    // translateY: translateY_tubo1
                }}
            >
                <motion.img src={require("../assets/tubogas2.png")} className={`object-contain w-full mb-3 ${productFadeIn?'animate-fadeIn':''}`}
                    style={{
                        opacity: 0 //opacity_tubo1,
                        // translateY: translateY_tubo1
                    }}
                ></motion.img>
                <div className="min-h-[50px] w-full flex flex-col justify-between items-center">
                    {/* <img src = {require("../assets/dots.png")} className="object-contain max-w-[150px] mb-3"></img> */}
                    <LineDot></LineDot>
                    <div className="text-white text-center !mb-0 product-name times-new">
                        Tubogas Yellow Gold Bracelet
                    </div>
                </div>
            </motion.div>
            
            <motion.div className={`max-w-[25%]  ${productFadeIn?'animate-fadeIn':''}`} 
                style={{
                    opacity: 0 // opacity_tubo1,
                    // translateY: translateY_tubo1
                }}
            >
                <motion.img src={require("../assets/tubogas1.png")} className={`object-contain w-full mb-3 ${productFadeIn?'animate-fadeIn':''}`} 
                    style={{
                        opacity: 0 //opacity_tubo1,
                        // opacity: opacity_tubo2,
                        // translateX: translateX_tubo2,
                        // translateY: translateY_tubo2
                    }}
                ></motion.img>
                <div className="min-h-[50px] w-full flex flex-col justify-between items-center">
                    {/* <img src = {require("../assets/dots.png")} className="object-contain max-w-[150px] mb-3"></img> */}
                    <LineDot></LineDot>
                    <div className="text-white text-center mb-0 product-name times-new">
                        BVLGARI BVLGARI Tubogas Watch
                    </div>
                </div>
            </motion.div>
            
            <motion.div className={`max-w-[28%]  ${productFadeIn?'animate-fadeIn':''}`} 
                style={{
                    opacity: 0 //opacity_tubo1,
                    // translateY: translateY_tubo1
                }}
            >
                <motion.img src={require("../assets/tubogas3.png")} className={`object-contain w-full mb-3  ${productFadeIn?'animate-fadeIn':''}`} 
                    style={{
                        opacity: 0 //opacity_tubo1,
                        // opacity: opacity_tubo3,
                        // translateX: translateX_tubo3,
                        // translateY: translateY_tubo3,
                    }}
                ></motion.img>
                <div className="min-h-[50px] w-full flex flex-col justify-between items-center">
                    {/* <img src = {require("../assets/dots.png")} className="object-contain max-w-[150px] mb-3"></img> */}
                    <LineDot></LineDot>
                    <div className="text-white text-center mb-0 product-name times-new">
                        Tubogas Yellow Gold Bracelet
                    </div>
                </div>
            </motion.div>
            
           
        </div>
    )
}

export default TubogasDesktop;