import React, { useEffect, useRef, useState } from "react";
import { useMotionValue, useMotionValueEvent, useScroll, useTransform, useInView, motion } from "framer-motion";

const NewStart = ({setAbsolute, _ref, change_fixed, blur, setChangeFixed}) => {

    const [scale_star, setScaleStar] = useState(false);
    
    const [add_bg, setAddBg] = useState(false);
    const [map_video, setMapVideo] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [change_absolute, setChangeAbsolute] = useState(false);
    const [add_bg_color, setAddBgColor] = useState(false);

    const containerx = useRef()
    const container1 = useRef();
    const container2 = useRef();
    const container3 = useRef();
    const container4 = useRef();
    const container5 = useRef();
    const container6 = useRef();

    const container_serper = useRef();
    const video_ref = useRef();
    
    const first_time_render = useRef(true);

    useEffect(() => {
        if (!change_absolute && blur) {

            if (first_time_render.current) {
                console.log("first time nothging to do");
                first_time_render.current = false;
                return;
            }
            
            const snapElements = document.querySelectorAll('.snap-center, .snap-stop');

            snapElements.forEach(el => {
                // Check if the element does not have the 'exclude-snap' class
                if (!el.classList.contains('exclude-snap')) {
                    el.classList.remove('snap-center', 'snap-stop');
                }
            });  

            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
            
            setTimeout(() => {

                console.log("add snap-center and snap-stop again");
                const snapElements = document.querySelectorAll('.put-again-snap');
  
                snapElements.forEach(el => {
                  el.classList.add('snap-center', 'snap-stop');
                });
                setChangeFixed(false);
            }, 1000);

            console.log("auto scroll here", first_time_render.current);
        }
    }, [change_absolute, blur])

    useEffect(() => {
        console.log("CHANGE FROM APPP JSON AFTER LCICKINGNGNGN", change_fixed)
        if (change_fixed) {
            first_time_render.current = false;
            console.log("change to fixed again");
            setChangeAbsolute(false);
        }
    }, [change_fixed]);

    const updateScreen = () => {
        if (window.innerWidth <= 576) { // Define breakpoint for mobile
            setMapVideo(true);
        } else {
            setMapVideo(false);
        }
    };

    useEffect(() => {
        // Initial check on component mount
        updateScreen();

        // Update margin on window resize
        window.addEventListener('resize', updateScreen);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', updateScreen);
    }, []);

    useEffect(() => {
        if (loaded) {
            setTimeout(() => {
                setAddBgColor(true);
            }, 1500)
        }
    }, [loaded]);

    useEffect(() => {
        // Check the screen size when the component mounts
        const mediaQuery = window.matchMedia('(max-width: 576px)');
        
        if (mediaQuery.matches) {
            setMapVideo(true);
        }
      
        setLoaded(true);
    }, []);

    useEffect(() => {
        // Check if the video is autoplaying at component mount
        const handlePlay = () => {
            setAddBg(true);
        };

        const video = video_ref.current;
        
        if (video) {
            video.addEventListener('play', handlePlay);
       
            // Check if the video is already playing on load
            if (!video.paused) {
                handlePlay();
            }
        }

        return () => {
            // Cleanup the event listener on unmount
            if (video) {
                video.removeEventListener('play', handlePlay);
            }
        };
    }, []);

    const [serper_index, setSerperIndex] = useState("01");
    
    const { scrollYProgress: scrollYProgress1 } = useScroll({
        target: container1,
        offset: ["start start", "end start"]
    });

    const { scrollYProgress: scrollYProgress2 } = useScroll({
        target: container2,
        offset: ["start start", "end start"]
    });
        
    const { scrollYProgress: scrollYProgress3 } = useScroll({
        target: container3,
        offset: ["start start", "end start"]
    });

    const { scrollYProgress: scrollYProgress4 } = useScroll({
        target: container4,
        offset: ["start start", "end start"]
    });

    const { scrollYProgress: scrollYProgress5 } = useScroll({
        target: container5,
        offset: ["start start", "end start"]
    });

    const { scrollYProgress: scrollYProgress6 } = useScroll({
        target: container6,
        offset: ["start start", "end start"]
    });

    // const { scrollYProgress: scrollYProgress7 } = useScroll({
    //     target: container7,
    //     offset: ["start start", "end start"]
    // });

    // const { scrollYProgress: scrollYProgress8 } = useScroll({
    //     target: container8,
    //     offset: ["start start", "end start"]
    // }); 

    const { scrollYProgress: scrollYProgress9 } = useScroll({
        target: container_serper,
        offset: ["start end", "end end"]
    });
    
    useMotionValueEvent(scrollYProgress1, "change", (progress) => {
        if (progress > .5) {
            setScaleStar(true);
        }else {
            setScaleStar(false);
        }
        // console.log("container 111", progress);
    })

    useMotionValueEvent(scrollYProgress2, "change", (progress) => {
        // console.log("container 2", progress);
    })

    useMotionValueEvent(scrollYProgress3, "change", (progress) => {
        // console.log("container 3", progress);
    })

    useMotionValueEvent(scrollYProgress4, "change", (progress) => {
        // console.log("container 3", progress);
    })

    useMotionValueEvent(scrollYProgress6, "change", (progress) => {
        // console.log("container 5", progress);
        if(progress==1) {
            setChangeAbsolute(true)
        } else {
            setChangeAbsolute(false)
        }
    })  
    
    const scale = useTransform(scrollYProgress1, [0, 1], [1, 80]);
    const opacity1 = useTransform(scrollYProgress2, [0, 1], [0, 1]);
    const opacity2 = useTransform(scrollYProgress3, [0, 1], [0, 1]);
    const opacity3 = useTransform(scrollYProgress4, [0, 1], [1, 0]);
    const opacity4 = useTransform(scrollYProgress5, [.5, 1], [0, 1]);
    const opacity5 = useTransform(scrollYProgress6, [.5, 1], [1, 0]);

    useEffect(() => {
        // containerx.current.scrollIntoView({
        //     behavior: "smooth", // Optional: Adds smooth scrolling animation
        //     block: "start",     // Aligns the element to the top of the viewport
        // });
    }, []);

    const autoPlay = () => {
        video_ref.current.play();
    }

    return (
        // <div className={`relative ${add_bg_color ? 'bg-bulgari-yellow' : ''} ${add_bg ? 'bg-bulgari-yellow' : ''}`}
        <div className={`relative `}
            ref={ref => _ref.current = ref}
        >

            {/* <div className="absolute w-full h-screen bg-red-900 top-0">

            </div> */}

            {
                (loaded) ?
                // <div id = "parent_container " className={`w-screen flex justify-center items-center z-[5] ${change_absolute ? 'absolute bg-bulgari-yellow' : 'fixed'}`}
                <div id = "parent_container " className={`w-screen flex justify-center items-center z-[5] ${change_absolute ? 'absolute bg-bulgari-yellow' : 'fixed'}`}
                    style={{
                        backgroundImage: `url(${map_video ? require("../assets/opening_iphonecompress.jpg") : require("../assets/cleanmapcompress.png")})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: `100vh`,
                        overflow: "hidden",
                    }}
                >
                    <motion.div className=" absolute w-full h-full flex justify-center items-center z-[5] bg-bulgari-yellow"
                        style = {{
                            opacity: opacity4,
                            height: "100vh"
                        }}
                    >

                        <motion.img
                            src = {require("../assets/presented.png")}
                            className="absolute max-w-[200px]"
                            style = {{
                                opacity: opacity4
                            }}
                            >
                        </motion.img> 
                    </motion.div>
                            

                    <div className="snap-center w-full h-full absolute z-[5] flex justify-center items-center exclude-snap">
                        <motion.div className="flex items-center justify-between flex-col"
                            style = {{
                                opacity: opacity3
                            }}
                        >
                            <motion.img
                                src = {require("../assets/bulgari.png")}
                                style={{
                                    opacity: opacity1
                                }}
                                className="mb-5 sm:mb-10 w-[80%] sm:w-[100%]"
                            >
                            </motion.img>
                            <motion.img
                                src = {require("../assets/140newnewnew.png")}
                                style={{
                                    // opacity: 0
                                    opacity: opacity2
                                }}
                                className="max-w-[200px] sm:max-w-[300px]"
                            >
                            </motion.img>
                        </motion.div>
                    </div>

                    <motion.div className=" max-w-[100px] z-[1]"
                        initial = {{scale: 1}}
                        animate = {{scale: scale_star ? 80 : 1}}
                        transition={{duration: .6}}
                        style = {{
                            // scale: scale,
                            // opacity: .5
                            // opacity: 1
                        }}
                    >
                        <img src = {require("../assets/star.png")}></img>
                    </motion.div> 

                    <video src = {(map_video) ? require("../assets/video/opening_iphone.mp4") : require("../assets/video/map.mp4")} 
                        loop = {true} muted = {true} autoPlay = {true} playsInline = {true}
                        controls = {false}
                        ref = {video_ref}
                        className="w-full h-full absolute object-cover"
                        onLoadedData={autoPlay}
                    >
                    </video>

                </div> : null 
            }
                      
            <motion.div className="snap-center snap-stop h-screen w-screen z-[3] relative exclude-snap"
                ref = {container1}
            >
                {/* 1 */}
            </motion.div>

            <motion.div className="  h-[100vh] w-screen z-[3] relative"
                ref = {container2}
            >
                {/* 2 */}
            </motion.div>
            
            <motion.div className="  h-[100vh]  w-screen  relative z-[3] "
                ref = {container3}
            >
                {/* 3 */}
            </motion.div>
            
            <motion.div className="snap-center snap-stop  h-[100vh] w-screen  relative z-[3] exclude-snap"
            >
                {/* spacing */}
            </motion.div>

            <motion.div className="  h-screen w-screen  relative z-[3] "
                ref = {container4}
            >
                {/* 4 */}
            </motion.div>

            <motion.div className="  h-screen w-screen  relative z-[3] "
                ref = {container5}
            >
                {/* 5 */}
            </motion.div>
            
            {/* <motion.div className="snap-center snap-stop  h-screen w-screen  relative z-[3] " */}
            <motion.div className="snap-center snap-stop  h-screen w-screen  relative z-[3] exclude-snap"
                ref = {container6}
            >
                {/* 5 */}
            </motion.div>
                    
            {/* <motion.div className="h-screen w-screen  relative z-[3] "
                ref = {container9}
            >
            </motion.div> */}

            {/* 

            <motion.div className="h-screen w-screen  relative z-[3] bg-blue-900"
                ref = {container8}
            >
            </motion.div> */}

        </div>
    )
}

export default NewStart;
