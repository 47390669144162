import React, { useEffect, useRef, useState, useCallback } from "react";
import { useMotionValue, useMotionValueEvent, useScroll, useTransform, useInView, motion } from "framer-motion";
import DownArrow from "./DownArrow";
import { debounce } from "lodash";
import { getDeviceViewportHeight } from "../hooks/GetDeviceHeight";
import LineDot from "./LineDot";

const Snake = ({setShowBG}) => {

    const container6 = useRef();
    const container7 = useRef();

    const timer = useRef();
    const timer2 = useRef();

    const images = Array.from({ length: 29 }, (_, i) => require(`../assets/snake/serper${i}.png`));

    const [serper_index, setSerperIndex] = useState(0);

    const [arrow, setArrow] = useState(false);
    
    const { scrollYProgress: scrollYProgress6 } = useScroll({
        target: container6,
        offset: ["start end", "end start"]
    });
    
    const { scrollYProgress: scrollYProgress7 } = useScroll({
        target: container7,
        offset: ["start end", "end start"]
    });

    const opacity6 = useTransform(scrollYProgress6, [0, 0.5], [0, 1]);
    const opacity7 = useTransform(scrollYProgress7, [0.5, 1], [1, 0]);
    const translate_yellow = useTransform(scrollYProgress7, [0, 1], ['-10vh', '0vh']);
    
    useEffect(() => {
        if (serper_index == 14 || serper_index == 15) {
            setArrow(true);
            setTimeout(() => {
                setShowBG(true);
            }, 500);
        }else {
            setArrow(false);
        }
    }, [serper_index]);
    
    useMotionValueEvent(scrollYProgress6, "change", (progress) => {
        const newIndex = Math.ceil(progress * 29);
        console.log("new indexxx", newIndex);
        if(newIndex>0) {
            setSerperIndex(newIndex);
        }
    })
    
    return (
        <div className="relative z-[10] h-screen">

            <div className="w-screen bg-bulgari-yellow absolute z-[3000]">

            </div>

            {
                images.map((item, index) => {
                    return (
                        <motion.div key={index+1} className="top-[15vh] xs:top-[10vh] lg:top-[25vh] left-1/2 xl:left-auto transform -translate-x-1/2 lg:transform-none lg:right-auto xl:right-[10vw] z-[9999]" 
                            style={{position: 'fixed',opacity: opacity7,
                        }}
                        >
                            <motion.div className="w-[100%]"
                                style={{
                                    opacity: opacity6
                                }}
                            >
                                <img src = {item} key={index} className={`${(serper_index == index) ? 'scale-1' : 'scale-0'} object-contain max-w-[100vw] lg:max-w-[90%] xl:max-w-[100%]`}>
                                </img>
                            </motion.div>
                        </motion.div>
                    )
                })
            }
            
            <motion.div className="absolute snap-center snap-stop sm:p-[6rem] w-full h-full flex justify-center items-start z-[5] put-again-snap"
                style={{
                    opacity:1,
                }}
            >

                <motion.div className="container relative w-full h-full flex justify-center lg:justify-center lg:items-start flex-col "  
                    style={{opacity:opacity6}}
                    ref = {container6} >
                    <motion.div className="flex flex-col items-center lg:items-start mt-[450px] sm:pl-0 sm:pr-0 lg:mt-0"
                        style = {{
                            opacity: 1
                        }}
                    >
                        <div className="xl:text-[20px] gen-ryu tracking-[5px] sm:tracking-[8px] xl:tracking-[10px] mb-[10px]">
                            文藝復興之路  不斷蛻變
                        </div>
                        <div className="xl:text-[20px] gen-ryu tracking-[5px] sm:tracking-[8px] xl:tracking-[10px]">
                            經歷萬千羽化  孕育出粲然星光
                        </div>
                        <LineDot></LineDot>
                    </motion.div>
                </motion.div>
                
                            
            </motion.div>
      
            <motion.div className="absolute z-[1000] w-screen flex justify-center items-end pb-[20px]"
                style = {{
                    height: getDeviceViewportHeight()
                }}
                initial = {{opacity: 0}}
                animate = {{opacity: arrow ? 1 : 0}}
                transition={{duration: .6}}
            >
                <DownArrow></DownArrow>
            </motion.div>

            <motion.div className="h-[100vh] w-screen  relative z-[3] "
                ref = {container7}
            >
                {/* 7 */}
            </motion.div>

        </div>
    )
}

export default Snake;
