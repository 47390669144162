import React, { useState, useEffect, useRef } from "react";
import { useMotionValue, useMotionValueEvent, useScroll, useTransform, useInView, motion, progress } from "framer-motion";

const DivasDesktop = ({auto_show_girls}) => {

    const [tablet, setTablet] = useState(false);
    const [fadeIn1, setFadeIn1] = useState(false);
    const [fadeIn2, setFadeIn2] = useState(false);

    const timer1 = useRef();
    const timer2 = useRef();

    const updateScreen = () => {
        if (window.innerWidth <= 1024) { // Define breakpoint for mobile
            setTablet(true);
        } else {
            clearTimeout(timer1.current);
            clearTimeout(timer2.current);
            setTablet(false);
            setFadeIn1(false);
            setFadeIn2(false);
        }
    };

    useEffect(() => {
        // Initial check on component mount
        updateScreen();

        // Update margin on window resize
        window.addEventListener('resize', updateScreen);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', updateScreen);
    }, []);
    
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 1024px)');
        setTablet(mediaQuery.matches);
     
        const handleMediaChange = (e) => {
            setTablet(mediaQuery.matches);
        };
    
        mediaQuery.addEventListener('change', handleMediaChange);
    
        return () => mediaQuery.removeEventListener('change', handleMediaChange);
    }, []);

    useEffect(() => {
        if (tablet) {
            setFadeIn1(true);
        }
    }, [tablet]);

    useEffect(() => {
        if (fadeIn1) {
            if (timer1.current != null) {
                clearTimeout(timer1.current);
                timer1.current = setTimeout(() => {
                    setFadeIn1(false);
                    setFadeIn2(true);
                }, 2000);
            }else {
                timer1.current = setTimeout(() => {
                    setFadeIn1(false);
                    setFadeIn2(true);
                }, 2000);
            }
        }
    }, [fadeIn1]);

    useEffect(() => {
        if (fadeIn2) {
            if (timer2.current != null) {
                clearTimeout(timer2.current);
                timer2.current = setTimeout(() => {
                    setFadeIn2(false);
                    setFadeIn1(true);
                }, 1800);
            }else {
                timer2.current = setTimeout(() => {
                    setFadeIn2(false);
                    setFadeIn1(true);
                }, 1800);
            }
        }
    }, [fadeIn2]);

    return (
        <div className="relative w-screen flex justify-between h-[100vh]">

            {/* tablet */}
            <div className={`w-screen h-full ${tablet ? 'flex' : 'hidden'} justify-end flex-col relative items-center overflow-x-hidden`}>
                
                <div className="w-[100%] h-full relative">
                    <motion.img src = {require("../assets/BV_girl_01.png")} 
                        className={`absolute w-[30%] left-[-1%] z-[6] bottom-0`}
                        style={{
                            // opacity: 0,
                            animationDuration: "1s",
                            animationFillMode: "forwards",
                        }}
                        // initial={{ opacity: 0 }}
                        // animate={{ opacity: fadeIn1 ? 1 : 0 }}
                        // transition={{ duration: .6}}
                    >
                    </motion.img>
                    <motion.img src = {require("../assets/BV_girl_02.png")} 
                        className={`absolute w-[39%] left-[20%] bottom-0 z-[5]`}
                        style={{
                            // opacity: 0,
                            animationDuration: "1s",
                            animationFillMode: "forwards",
                        }}
                        // initial={{ opacity: 0 }}
                        // animate={{ opacity: fadeIn1 ? 1 : 0 }}
                        // transition={{ duration: .6}}
                    >
                    </motion.img>
                    <motion.div className={`absolute left-[3%] bottom-3 text-white z-[30] times-new`}
                        // initial={{ opacity: 0 }}
                        // animate={{ opacity: fadeIn1 ? 1 : 0 }}
                        // transition={{ duration: .6}}
                    >
                        Anne Hathaway
                    </motion.div>
                    <motion.div className={`absolute left-[30%] bottom-3 text-white z-[30] times-new`}
                        // initial={{ opacity: 0 }}
                        // animate={{ opacity: fadeIn1 ? 1 : 0 }}
                        // transition={{ duration: .6}}
                    >
                        Zendaya
                    </motion.div>
                        
                    <motion.img src = {require("../assets/BV_girl_03.png")} 
                        className={`absolute w-[42%] left-[35%] z-[4] bottom-0`}
                        // initial={{ opacity: 0 }}
                        // animate={{ opacity: fadeIn2 ? 1 : 0 }}
                        // transition={{ duration: .6}}
                    >
                    </motion.img>
                
                    <motion.img src = {require("../assets/BV_girl_04.png")} 
                        className={`absolute w-[38%] right-[-3%] bottom-0`}
                        // initial={{ opacity: 0 }}
                        // animate={{ opacity: fadeIn2 ? 1 : 0 }}
                        // transition={{ duration: .6}}
                    >
                    </motion.img> 

                    <motion.div className={`absolute left-[50%] bottom-3 text-white z-[30] times-new`}
                        // initial={{ opacity: 0 }}
                        // animate={{ opacity: fadeIn2 ? 1 : 0 }}
                        // transition={{ duration: .6}}
                    >
                        Lisa
                    </motion.div>

                    <motion.div className={`absolute right-[25%] bottom-3 text-white z-[30] gen-ryu-sb`}
                        // initial={{ opacity: 0 }}
                        // animate={{ opacity: fadeIn2 ? 1 : 0 }}
                        // transition={{ duration: .6}}
                    >
                        舒淇
                    </motion.div>
           
                </div>
            
            </div>

            {/* desktop */}
            <div className={`relative w-full h-full overflow-x-hidden ${tablet ? 'hidden' : 'block'}`}>
                <div className="absolute w-[23.4vw] bottom-0 left-[2vw] z-[5]">
                    {/* <motion.img src = {require("../assets/black1.png")}  */}
                    <motion.img src = {require("../assets/BV_girl_01.png")} 
                    className={`w-full relative ${auto_show_girls ? 'animate-fadeIn' : ''}`}
                        style={{
                            opacity: 0,
                            animationDuration: "1s",
                            animationFillMode: "forwards",
                            // opacity: each_women_opacity
                            // opacity: women_opacity
                            // opacity: woman2_opacity,
                            // translateX: woman2_translateX
                        }}
                    >
                    </motion.img>
                </div>

                <div className="absolute w-[30vw] bottom-0 left-[23vw] z-[6]">
                    {/* <motion.img src = {require("../assets/black2.png")} className={`w-full relative z-[5] ${auto_show_girls ? 'animate-fadeIn' : ''}`} */}
                    <motion.img src = {require("../assets/BV_girl_02.png")} 
                        className={`w-full relative ${auto_show_girls ? 'animate-fadeIn' : ''}`}
                        style={{
                            opacity: 0,
                            animationDuration: "1s",
                            animationFillMode: "forwards",
                            // opacity: each_women_opacity
                            // opacity: women_opacity
                            // opacity: woman1_opacity,
                        }}
                    >
                    </motion.img>
                </div>
            
                <div className="absolute w-[33vw] bottom-0 right-[25vw] z-[4]">
                    {/* <motion.img src = {require("../assets/black3.png")} className={`w-full relative z-[5] ${auto_show_girls ? 'animate-fadeIn' : ''}`} */}
                    <motion.img src = {require("../assets/BV_girl_03.png")} 
                        className={`w-full relative ${auto_show_girls ? 'animate-fadeIn' : ''}`}
                        style={{
                            opacity: 0,
                            animationDuration: "1s",
                            animationFillMode: "forwards",
                            // opacity: each_women_opacity
                            // opacity: women_opacity
                        }}
                    >
                    </motion.img>

                </div>

                <div className="absolute w-[30vw] bottom-0 right-[0vw] z-[3]">
                    {/* <motion.img src = {require("../assets/black4.png")} className={`w-full relative z-[2] ${auto_show_girls ? 'animate-fadeIn' : ''}`} */}
                    <motion.img src = {require("../assets/BV_girl_04.png")} 
                        className={`w-full relative ${auto_show_girls ? 'animate-fadeIn' : ''}`}
                        style={{
                            opacity: 0,
                            animationDuration: "1s",
                            animationFillMode: "forwards",
                            // opacity: each_women_opacity
                            // opacity: women_opacity
                            // opacity: woman4_opacity,
                            // translateX: woman4_translateX
                        }}
                    >
                    </motion.img>

                </div> 

                <motion.div className={`absolute left-[4vw] bottom-3 text-white z-[30] times-new ${auto_show_girls ? 'animate-fadeIn' : ''}`} style={{
                    opacity: 0,
                    animationDuration: "1s",
                    animationFillMode: "forwards",
                    // opacity: each_women_opacity
                }}>Anne Hathaway
                </motion.div>
                <motion.div className={`absolute left-[28vw] bottom-3 text-white z-[30] times-new ${auto_show_girls ? 'animate-fadeIn' : ''}`} style={{
                    opacity: 0,
                    animationDuration: "1s",
                    animationFillMode: "forwards",
                }}>Zendaya</motion.div>
                <motion.div className={`absolute left-[53vw] bottom-3 text-white z-[300] times-new ${auto_show_girls ? 'animate-fadeIn' : ''}`} style={{
                    opacity: 0,
                    animationDuration: "1s",
                    animationFillMode: "forwards",
                }}>Lisa</motion.div>
                <motion.div className={`absolute left-[75vw] bottom-3 text-white z-[30] gen-ryu-sb ${auto_show_girls ? 'animate-fadeIn' : ''}`} style={{
                    opacity: 0,
                    animationDuration: "1s",
                    animationFillMode: "forwards",
                }}>舒淇</motion.div>

            </div>
           
        </div>


    )
}

export default DivasDesktop;