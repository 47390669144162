import React, { useState, useEffect, useRef } from "react";
import { useMotionValue, useMotionValueEvent, useScroll, useTransform, useInView, motion } from "framer-motion";
import LineDot from "./LineDot";

const TubogasMobile = ({productFadeIn}) => {

    const timer_ref = useRef();
    const timer2_ref = useRef();
    const timer3_ref = useRef();


    const [image_index, setImageIndex] = useState(1);
    const [fadeIn, setFadeIn] = useState(false);

    const items = [
        {
            image: require("../assets/tubogas2.png"),
            name: `      
                Tubogas Yellow Gold Bracelet
            `
        },
        {
            image: require("../assets/tubogas1.png"),
            name: `
                BVLGARI BVLGARI Tubogas Watch
            `
        },
        {
            image: require("../assets/tubogas3.png"),
            name: `Tubogas Yellow Gold Bracelet
            `
        },
    ];

    useEffect(() => {
        if (productFadeIn) {
            if (timer_ref.current != null) {
                clearInterval(timer_ref.current);
                timer_ref.current = setInterval(() => {
                    setImageIndex((old) => {
                        return old >= items.length - 1 ? 0 : old + 1;
                    });
                }, 2200);
            }else {
                timer_ref.current = setInterval(() => {
                    setImageIndex((old) => {
                        return old >= items.length - 1 ? 0 : old + 1;
                    });
                }, 2200);
            }
        }else {
            setImageIndex(0);
            setFadeIn(false);
            clearTimeout(timer2_ref.current);
            clearTimeout(timer3_ref.current);
            clearInterval(timer_ref.current);
        }
    }, [productFadeIn]);

    useEffect(() => {
        if (productFadeIn) {
            clearTimeout(timer3_ref.current);
            timer3_ref.current = setTimeout(() => {
                setFadeIn(true); // Trigger fade-in animation
            }, 300);
            clearTimeout(timer2_ref.current);
            timer2_ref.current = setTimeout(() => {
                setFadeIn(false);
            }, 1500);
        } 
    }, [productFadeIn, image_index]); // Depend on image_index to trigger on change


    return (
        <div className="flex w-screen items-center justify-around "
            // ref={serpenti_text}
        >
            <motion.div className={`${productFadeIn?'animate-fadeIn':''} `}
                style={{
                    opacity: 0 //opacity_tubo1,
                    // translateY: translateY_tubo1
                }}
            >
                <div className="h-[29vh] flex flex-col items-center">
                    <motion.img src={items[image_index].image} className={`object-contain h-full mb-3`}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: fadeIn ? 1 : 0 }}
                        transition={{ duration: .6}}
                    ></motion.img>
                    <motion.div className="w-full flex flex-col justify-between items-center"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: fadeIn ? 1 : 0 }}
                        transition={{ duration: .6}}
                    >
                        <LineDot></LineDot>
                        {/* <img src = {require("../assets/dots.png")} className="object-contain max-w-[150px] mb-3"></img> */}
                        <div className="text-white text-center !mb-0 product-name times-new"
                            dangerouslySetInnerHTML={{ __html: items[image_index].name }} 
                        >
                        </div>
                    </motion.div> 
                </div>
            </motion.div>
            
        </div>
    )
}

export default TubogasMobile;