import Rect from "react";

const LineDot = () => {

    return (
        <div className="h-[50px] flex justify-center items-center">
            <img src = {require("../assets/dots.png")} className="object-contain max-w-[150px]"></img>
        </div>
    )
}

export default LineDot;