import React, { useEffect, useRef, useState } from "react";
import { useMotionValue, useMotionValueEvent, useScroll, useTransform, useInView, motion, progress } from "framer-motion";
import DownArrow from "./DownArrow";
import DivasMobile from "./DivasMobile";
import DivasDesktop from "./DivasDesktop";
import { getDeviceViewportHeight } from "../hooks/GetDeviceHeight";
import LineDot from "./LineDot";

const DivasTest = ({divas_opacity, fade_out_bubbles, fade_out_divas, scroll_divas, auto_show_girls}) => {

  
    const parent_container = useRef();
    const elements_container = useRef();
    const container1 = useRef();
    const container2 = useRef();
    const container_fade_triangle = useRef();
    const container3 = useRef();
    const container4 = useRef();
    const container5 = useRef();
    const container6 = useRef();
    const container7 = useRef();
    const container8 = useRef();
    const container9 = useRef();
    const container10 = useRef();

    // const [auto_show_girls, setAutoShowGirls] = useState(false);
    const [mobile, setMobile] = useState(false);

    const [bubbleDone, setBubbleDone] = useState(false);
    
    const [triangle_index, setTriangleIndex] = useState(1);
    const [triangle_arrow, setTriangleArrow] = useState(false);

    const triangle_timer = useRef();

    const { scrollYProgress: scrollYProgressBuuble } = useScroll({
        target: elements_container,
        offset: ["start end", "end start"]
    });

    const { scrollYProgress: scrollYProgress1 } = useScroll({
        target: parent_container,
        offset: ["start end", "start start"]
    });

    const { scrollYProgress: scrollYProgress2 } = useScroll({
        target: container1,
        offset: [`start -${300}px`, "end start"]
    });

    const { scrollYProgress: scrollYProgress3 } = useScroll({
        target: container2,
        offset: [`start start`, "end start"]
    });

    const { scrollYProgress: scrollYProgress4 } = useScroll({
        target: container3,
        offset: [`start end`, "end end"]
    }); 

    const { scrollYProgress: scrollYProgress5 } = useScroll({
        target: container4,
        offset: [`start end`, "end end"]
    });

    const { scrollYProgress: scrollYProgress6 } = useScroll({
        target: container5,
        offset: [`start end`, "end end"]
    });

    const { scrollYProgress: scrollYProgress7 } = useScroll({
        target: container6,
        offset: [`start end`, "end end"]
    });

    const { scrollYProgress: scrollYProgress8 } = useScroll({
        target: container7,
        offset: [`start end`, "end end"]
    });

    const { scrollYProgress: scrollYProgress9 } = useScroll({
        target: container8,
        offset: [`start end`, "end end"]
    });

    const { scrollYProgress: scrollYProgress10 } = useScroll({
        target: container9,
        offset: [`start end`, "end end"]
    });

    const { scrollYProgress: scrollYProgress11 } = useScroll({
        target: container10,
        offset: [`start end`, "end end"]
    });


    const { scrollYProgress: scrollYProgress12 } = useScroll({
        target: container2,
        offset: ["start start", "end start"]
    });

    useMotionValueEvent(scrollYProgress1, "change", (progress) => {
        if(progress==1) {
            setBubbleDone(true)
        } else {
            setBubbleDone(false)
        }
    })

    useMotionValueEvent(scrollYProgress8, "change", (progress) => {
        //console.log("blue div rectangle", progress);
        // alert(progress);
        if (Math.round(progress) >= 1) {
            // alert("1");
            // setAutoShowGirls(true);
            // setTriangleIndex(1);
            // clearInterval(triangle_timer.current);
        } else if (progress > 0 && progress < 0.5) {
            // alert("no 1");
            // setAutoShowGirls(false);
        } else if (progress === 0) {
            // alert("0");
            // autoChangeTriangle();
        }
        // if (Math.round(progress >= 1)) {
        //     alert("1");
        //     setAutoShowGirls(true);
        //     setTriangleIndex(1);
        //     clearInterval(triangle_timer.current);
        // }
        // if (progress > 0 && progress < .5) {
        //     alert("no 1");
        //     setAutoShowGirls(false);
        // }
        // if (progress == 0) {
        //     alert("0");
        //     autoChangeTriangle();
        // }
     
    });

    useMotionValueEvent(scrollYProgress3, "change", (progress) => {
        if(progress>0) {
            setBubbleDone(false)
        } else {
            setBubbleDone(true)
        }
        // console.log("triangle 1111 container", progress);
        if (Math.round(progress) == 1) {
            // autoChangeTriangle();
            setTriangleArrow(true);
        }else {
            // setTriangleIndex(1);
            setTriangleArrow(false);
            // clearInterval(triangle_timer.current);
        }
     
    });

    useEffect(() => {
        // console.log("fadeout bublesss", fade_out_bubbles);
        if (fade_out_bubbles) {
            // console.log("executee");
            autoChangeTriangle();
        }else {
            clearInterval(triangle_timer.current);
            setTriangleIndex(1);
        }
    }, [fade_out_bubbles]);

    useMotionValueEvent(scrollYProgress4, "change", (progress) => {
        // console.log("progress for triangleee", progress);
    });

    const spring = {
        type: "spring",
        stiffness: 50,
        damping: 10
    };

    const bubble_translate = useTransform(scrollYProgressBuuble, [0, 1], ["35vw", "0vh"]);

    const text_container = useTransform(scrollYProgress1, [0, 1], [0,1]);
    const bulgari_yard = useTransform(scrollYProgress2, [0, 1], [0, 1]);

    
    const opacity_triange1 = useTransform(scrollYProgress3, [0, 1], [1, 0]);
    
    const opacity_triange2 = useTransform(scrollYProgress3, [0, 1], [0, 1]);
    const opacity_triange2_0 = useTransform(scrollYProgress4, [0, 1], [1, 0]);

    const opacity_triange3 = useTransform(scrollYProgress4, [0, 1], [0, 1]);
    const opacity_triange3_0 = useTransform(scrollYProgress5, [0, 1], [1, 0]);
   
   
    const opacity_triange4 = useTransform(scrollYProgress5, [0, 1], [0, 1]);
    const opacity_triange4_0 = useTransform(scrollYProgress6, [0, 1], [1, 0]);
   
    const opacity_triange5 = useTransform(scrollYProgress6, [0, 1], [0, 1]);


    const opacity_triangle_container = useTransform(scrollYProgress8, [0, 1], [1, 0]);

    const divas_container_opacity = useTransform(scrollYProgress9, [0, 1], [0, 1]);
    const divas_container_translate = useTransform(scrollYProgress9, [0, 1], ["100%", "0%"]);

    const women_opacity = useTransform(scrollYProgress10, [0, 1], [0, 1]);
    const women_letter_opacity = useTransform(scrollYProgress11, [0, 1], [0, 1]);

    const opacity_triangle_container_parent = useTransform(scrollYProgress12, [0, 1], [0,1]);

    const divas_and_triangle_opacity = useTransform(scrollYProgress4, [0, 1], [0, 1]);
    const divas_and_triangle_parent_opacity = useTransform(scrollYProgress8, [0, .5], [1, 0]);
   
    const divas_women_container_opacity = useTransform(scroll_divas, [0, .5], [0, 1]);
    const divas_women_container_translateY = useTransform(scroll_divas, [0, .5], ["100%", "0%"]);
    
    const each_women_opacity = useTransform(scrollYProgress9, [0, 1], [0,1]);

    
    const autoChangeTriangle = () => {
        clearInterval(triangle_timer.current);
        triangle_timer.current = setInterval(() => {
            // console.log("execute function");
            // console.log("herere start");
            setTriangleIndex((old) => {
                return old >= 5 ? 1 : old + 1;
            });
        }, 1000);
        
        // if (triangle_timer.current != null) {
        //     console.log("exeucte firs timeee");
        //     clearInterval(triangle_timer.current);
        //     triangle_timer.current = setInterval(() => {
        //         // console.log("execute function");
        //         console.log("herere");
        //         setTriangleIndex((old) => {
        //             return old >= 5 ? 1 : old + 1;
        //         });
        //     }, 1000);
        // }else {

        //     console.log("exeucte seoncd timeee");
        //     triangle_timer.current = setInterval(() => {
        //         // console.log("execute function");
        //         setTriangleIndex((old) => {
        //             return old >= 5 ? 1 : old + 1;
        //         });
        //     }, 1000);
        // }
    }

    useEffect(() => {
        // Check the screen size when the component mounts
        const mediaQuery = window.matchMedia('(max-width: 576px)');
        setMobile(mediaQuery.matches);
     
        // Event listener for screen size changes
        const handleMediaChange = (e) => {
            setMobile(mediaQuery.matches);
        };
    
        // Add listener for resizing
        mediaQuery.addEventListener('change', handleMediaChange);
    
        // Clean up listener on unmount
        return () => mediaQuery.removeEventListener('change', handleMediaChange);
    }, []);

    return (
        // <motion.div className={`absolute w-full h-full z-[1] ${divas_opacity ? 'opacity-1' : 'opacity-0'}`}
        <motion.div className={`absolute w-full h-full z-[1] ${divas_opacity ? 'opacity-1' : 'opacity-0'}`}
            ref={parent_container}
        >

            <div className="w-screen relative h-screen">
          
                {/* <div className="sticky top-0  bg-bulgari-yellow w-screen h-screen "> */}
                <div className="absolute w-full h-full z-[100]"
                    ref={elements_container}
                    style={{
                        // opacity: .3
                    }}
                >
                    <div className="relative w-full h-full flex justify-center items-center">
                        
                        <motion.div className="absolute w-screen h-full flex justify-center items-center z-[100]"
                            style={{
                                // opacity: bulgari_yard,
                                opacity: 1
                            }}
                        >

                            <div className="relative w-full h-full">

                                <motion.div className="absolute w-full h-full" 
                                >
                        
                                    <motion.div className={`absolute w-full h-full flex flex-col items-center justify-center ${fade_out_divas ? 'animate-fadeOut' : ''}`}
                                        style={{
                                            opacity: 1,
                                            animationDuration: ".3s",
                                            animationFillMode: "forwards"
                                        }}
                                    >
                                        <motion.div className={`relative w-full h-screen flex flex-col items-center justify-center ${fade_out_bubbles ? 'animate-fadeIn' : ''}`}
                                            style={{
                                                opacity: 0,
                                                animationDuration: ".6s",
                                                animationDelay: ".2s",
                                                animationFillMode: "forwards"
                                            }}
                                        >
                                            <div className="min-h-[400px] flex flex-col justify-around items-center">
                                                {/* cl code <motion.div className="h-[80px] mt-[20vh] flex items-center flex-col justify-between" */}
                                                <motion.div className="h-[80px] flex items-center flex-col justify-between mb-5">
                                                    <img src = {require("../assets/star.png")} className="max-w-[30px]"></img>
                                                    <div className="!font-normal text-spacing text-center !mb-0 text-[1.2rem] times-new">DIVAS' DREAM</div>
                                                </motion.div>
                                                <motion.img src = {require(`../assets/triangle${triangle_index}newnew.png`)} className={`object-contain w-[250px] sm:w-[300px] z-[5]`}>
                                                </motion.img>
                                            </div>
                                       
                                        </motion.div>

                                    </motion.div> 

                                    {/* divas */}
                                    <motion.div
                                        className="absolute w-full h-full divas-container-background-image"
                                        style={{
                                            opacity: divas_women_container_opacity,
                                            translateY: divas_women_container_translateY,
                                        }}
                                    >

                                        <div className="relative w-full h-full flex flex-col items-center justify-center pt-[18vh] xs:pt-[15vh] pb-0 overflow-hidden">

                                            <img src = {require("../assets/divasbackground.png")} className="absolute w-screen h-screen top-0 hidden lg:block"></img>

                                            <img src = {require("../assets/divasbackground.png")} className="absolute w-screen h-screen top-0 object-cover block lg:hidden"></img>

                                            <motion.div className={`flex flex-col justify-around ${auto_show_girls ? 'animate-fadeIn' : ''}`}
                                                style={{
                                                    opacity: 0,
                                                    animationDuration: "1s",
                                                    animationDelay: ".3s",
                                                    animationFillMode: "forwards",
                                                }}
                                            >

                                                {/* mobile - tablet   */}
                                                <motion.div className="flex xs:hidden flex-col w-[350px] sm:w-auto"
                                                >
                                                    <div className="flex items-center justify-center flex-col">
                                                        <div className="text-white title-mobile tracking-[4px] text-white-margin-bottom gen-ryu">
                                                            千嬌百媚
                                                        </div>
                                                        <div className="text-white title-mobile tracking-[4px] gen-ryu" >
                                                            繼續閃耀不同世代芳華
                                                        </div>
                                                    </div>
                                                </motion.div>

                                                 {/* desktop */}
                                                <motion.div className="hidden xs:flex flex-col w-[350px] sm:w-auto"
                                                >
                                                    <div className="flex items-center justify-center flex-col">
                                                        <div className="text-white white-header mb-0 gen-ryu">
                                                            千嬌百媚
                                                        </div>
                                                        <div className="text-white white-header gen-ryu" >
                                                            繼續閃耀不同世代芳華
                                                        </div>
                                                    </div>
                                                </motion.div>

                                                <motion.div className="flex items-center justify-center"
                                                >
                                                    <LineDot></LineDot>
                                                </motion.div>

                                            </motion.div>
                                            
                                            {
                                                (mobile) ?
                                                <DivasMobile auto_show_girls={auto_show_girls}></DivasMobile>
                                                :
                                                <DivasDesktop auto_show_girls={auto_show_girls}></DivasDesktop>
                                            }
 
                                        </div> 

                                    </motion.div>
                                
                                </motion.div>
                              
                                <motion.div className="absolute w-screen top-0 flex items-end justify-center pb-[15px] z-[100]"
                                    style = {{
                                        height: getDeviceViewportHeight()
                                    }}
                                    initial = {{opacity: 0}}
                                    animate = {{opacity: triangle_arrow ? 1 : 0}}
                                    transition={{duration: .6, delay: .6}}
                                >
                                    <DownArrow></DownArrow>
                                </motion.div>
                          
                            </div>
                            
                        </motion.div>
                        
                        {/* down arrow */}
                        {/* <div className={`absolute w-screen z-[1000] bottom-0 ${bubbleDone ? 'animate-fadeIn' : ''}`}
                            style={{
                                opacity: 0,
                                animationDelay: ".5s",
                                animationFillMode: "forwards"
                            }}
                        >
                            <DownArrow></DownArrow>
                        </div> */}

                    </div>

                </div>
                
                {/* pool video */}
                <div className="absolute top-0 w-full h-full z-[1]">
                    <video src = {require("../assets/video/poolbulgarismall.mp4")} className="w-full h-full object-cover"
                        autoPlay = {true}
                        muted = {true}
                        loop = {true}
                        playsInline = {true}
                        // ref={video_ref}
                        preload = {true}
                    >
                    </video>
                </div>
                
                {/* <div className="h-screen w-screen"
                    ref={container8}
                >
                     divas container 
                </div> */}

                {/* <div className="snap-center snap-stop h-[100vh] w-screen"
                    ref={container9}
                >
                    {/* show women text */}
                    {/* starting for women 
                </div> */}
                
            </div>
            
          
        </motion.div>
    )
}

export default DivasTest;