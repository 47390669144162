import React, { useState, useEffect, useRef } from "react";
import { useMotionValue, useMotionValueEvent, useScroll, useTransform, useInView, motion, useAnimation } from "framer-motion";
import DownArrow from "./DownArrow";
import LineDot from "./LineDot";

const ItemsMobile = ({showProduct}) => {

    const timer_ref = useRef();
    const timer2_ref = useRef();
    const timer3_ref = useRef();

    const [image_index, setImageIndex] = useState(1);
    const [fadeIn, setFadeIn] = useState(false);

    const items = [
        {
            image: require("../assets/item1.png"),
            width: "100%",
            name: "Serpenti Viper Ring"
        },
        {
            image: require("../assets/item2.png"),
            width: "80%",
            name: "Serpenti Sugarloaf Shoulder Bag"
        },
        {
            image: require("../assets/item3test2.png"),
            width: "80%",
            name: "Aeterna Serpenti Anima"
        },
        {
            image: require("../assets/item4.png"),
            width: "100%",
            name: "Serpenti Tubogas Bracelet"
        }
    ];

    useEffect(() => {
        if (showProduct) {
            if (timer_ref.current != null) {
                clearInterval(timer_ref.current);
                timer_ref.current = setInterval(() => {
                    setImageIndex((old) => {
                        return old >= items.length - 1 ? 0 : old + 1;
                    });
                }, 2200);
            }else {
                timer_ref.current = setInterval(() => {
                    setImageIndex((old) => {
                        return old >= items.length - 1 ? 0 : old + 1;
                    });
                }, 2200);
            }
        }else {
            setImageIndex(0);
            setFadeIn(false);
            clearTimeout(timer2_ref.current);
            clearTimeout(timer3_ref.current);
            clearInterval(timer_ref.current);
        }
    }, [showProduct]);

    useEffect(() => {
        if (showProduct) {
            clearTimeout(timer3_ref.current);
            timer3_ref.current = setTimeout(() => {
                setFadeIn(true); // Trigger fade-in animation
            }, 300);
            clearTimeout(timer2_ref.current);
            timer2_ref.current = setTimeout(() => {
                setFadeIn(false);
            }, 1500);
        } 
    }, [showProduct, image_index]); // Depend on image_index to trigger on change

    return (
        <div className={`flex flex-grow w-screen z-10 pt-2 ${showProduct ? 'animate-fadeIn' : ''}`} id="products">
            <div className="container flex flex-col relative">
                <motion.div className="flex items-center flex-col "
                    style={{
                        
                    }}
                >
                    <div className="h-[30vh] w-screen flex justify-center items-center flex-col">
                        <motion.img 
                            src={items[image_index].image}
                            style={{
                                height: "80%",
                            }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: fadeIn ? 1 : 0 }}
                            transition={{ duration: .6}}
                        ></motion.img>
                        <motion.div src = {require("../assets/dots.png")}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: fadeIn ? 1 : 0 }}
                            transition={{ duration: .6}}
                        >
                            <LineDot></LineDot>
                        </motion.div>
                        <motion.div className="text-white text-center !mb-0 product-name times-new"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: fadeIn ? 1 : 0 }}
                            transition={{ duration: .6}}
                        >
                            {items[image_index].name}
                        </motion.div>
                    </div>
                </motion.div>           
            </div>
        </div>
    )
}


export default ItemsMobile;