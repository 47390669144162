import React, { useState, useEffect, useRef } from "react";
import { useMotionValue, useMotionValueEvent, useScroll, useTransform, useInView, motion, useAnimation } from "framer-motion";
import LineDot from "./LineDot";

const WatchesMobile = ({done_octacore}) => {

    const timer_ref = useRef();
    const timer2_ref = useRef();
    const timer3_ref = useRef();

    const [image_index, setImageIndex] = useState(1);
    const [fadeIn, setFadeIn] = useState(false);

    const text_index = 4;

    const items = [
        {
            image: require("../assets/bulgari_watch4_optimized.png"),
            name: "Octo Finissimo Automatic",
            margin_bottom: "0px",
            left: -3,
        },
        {
            image: require("../assets/bulgari_watch_optimized.png"),
            name: "BVLGARI Aluminium White Automatic",
            margin_bottom: "0px",
            left: 15,
        },
        {
            image: require("../assets/bulgari_watch2_1_optimized.png"),
            name: "Octo Roma Worldtimer",
            margin_bottom: "0px",
            left: -3,
        },
        {
            image: require("../assets/bulgari_watch5_optimized.png"),
            name: "Octo Roma Grand Sonnerie",
            left: 7,
        },
        {
            image: require("../assets/bulgari_watch3_optimized.png"),
            name: "Octo Finissimo Automatic",
            margin_bottom: "0px",
            left: -2,
        }
    ];

    useEffect(() => {
        if (done_octacore) {
            if (timer_ref.current != null) {
                clearInterval(timer_ref.current);
                timer_ref.current = setInterval(() => {
                    setImageIndex((old) => {
                        return old >= items.length - 1 ? 0 : old + 1;
                    });
                }, 2200);
            }else {
                timer_ref.current = setInterval(() => {
                    setImageIndex((old) => {
                        return old >= items.length - 1 ? 0 : old + 1;
                    });
                }, 2200);
            }
        }else {
            setImageIndex(0);
            setFadeIn(false);
            clearTimeout(timer2_ref.current);
            clearTimeout(timer3_ref.current);
            clearInterval(timer_ref.current);
        }
    }, [done_octacore]);

    useEffect(() => {
        if (done_octacore) {
            clearTimeout(timer3_ref.current);
            timer3_ref.current = setTimeout(() => {
                setFadeIn(true); // Trigger fade-in animation
            }, 300);
            clearTimeout(timer2_ref.current);
            timer2_ref.current = setTimeout(() => {
                setFadeIn(false);
            }, 1500);
        } 
    }, [done_octacore, image_index]); // Depend on image_index to trigger on change

    return (
        <motion.div className={ `${done_octacore ? 'animate-fadeIn' : ''}`}
            style={{
                opacity: 0,
                animationDuration: "1s",
                animationFillMode: "forwards"
                // translateY: watches_container_y,
            }}
        >
            <motion.div className="flex flex-col items-center justify-center"
            >
                <motion.img src = {items[image_index].image} className="object-contain max-h-[60vh] ml-[15px]"
                    style={{
                        marginLeft: items[image_index].left + 'px'
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: fadeIn ? 1 : 0 }}
                    transition={{ duration: .6}}
                >
                </motion.img>
                <div className="h-[80px] flex flex-col items-center justify-center">
                    <motion.div src = {require("../assets/dots.png")}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: fadeIn ? 1 : 0 }}
                        transition={{ duration: .6}}
                    >
                        <LineDot></LineDot>
                    </motion.div>
                    <motion.div className="text-white text-center !mb-0 product-name times-new"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: fadeIn ? 1 : 0 }}
                        transition={{ duration: .6}}
                    >
                        {items[image_index].name}
                    </motion.div>
                </div>
            </motion.div>
    
        </motion.div>
    )
}


export default WatchesMobile;