import React, { useState, useEffect, useRef } from "react";
import { useMotionValue, useMotionValueEvent, useScroll, useTransform, useInView, motion, useAnimation } from "framer-motion";

const WatchesDesktop = ({done_octacore}) => {

    return (
        <motion.div className={`flex max-w-[1500px] w-[90vw] xl:w-[80vw] justify-around ${done_octacore ? 'animate-fadeIn' : ''}`}
            style={{
                opacity: 0,
                animationDuration: "1s",
                animationFillMode: "forwards"
                // translateY: watches_container_y,
            }}
        >
            <motion.div className="flex flex-col justify-between items-center"
                style={{
                }}
            >
                <motion.img src = {require("../assets/bulgari_watch3_optimized.png")} className="object-contain w-[13vw]"
                    style={{
                    }}
                >
                </motion.img>
                <div className="h-[50px] w-full justify-between flex flex-col items-center">
                    <img src = {require("../assets/dots.png")} className="object-contain max-w-[100px]"></img>
                    <div className="text-white text-center mb-0  product-name">
                    Octo Finissimo Automatic
                    </div>
                </div>
            </motion.div>
            
            <motion.div className="flex flex-col justify-between items-center"
                style={{
                }}
            >
                <motion.img src = {require("../assets/bulgari_watch4_optimized.png")} className="object-contain w-[12.5vw]"
                    style={{
                    }}
                >
                </motion.img>
                <div className="h-[50px] w-full justify-between flex flex-col items-center">
                    <img src = {require("../assets/dots.png")} className="object-contain max-w-[100px]"></img>
                    <div className="text-white text-center mb-0  product-name">
                    Octo Finissimo Automatic 
                    </div>
                </div>
            </motion.div>

            <motion.div className="flex flex-col justify-between items-center"
                style={{
                }}
            >
                <motion.img src = {require("../assets/bulgari_watch_optimized.png")} className="object-contain w-[13vw] mb-3"
                    style={{
                    }}
                >
                </motion.img>
                <div className="min-h-[50px] w-full justify-between flex flex-col items-center">
                    <div className="">
                        <img src = {require("../assets/dots.png")} className="object-contain max-w-[100px]"></img>
                    </div>
                    <div className="text-white text-center mb-0 product-name">
                        BVLGARI Aluminium White Automatic
                    </div>
                </div>
            </motion.div>
        
            <motion.div className="flex flex-col justify-between items-center"
                style={{
                }}
            >
            <motion.img src = {require("../assets/bulgari_watch2_1_optimized.png")} className="object-contain w-[13.5vw]"
                style={{
                }}
            >
                </motion.img>
                <div className="min-h-[50px] w-full justify-between flex flex-col items-center">
                    <div className="">
                        <img src = {require("../assets/dots.png")} className="object-contain max-w-[100px]"></img>
                    </div>
                    <div className="text-white text-center mb-0 product-name">
                        Octo Roma Worldtimer 
                    </div>
                </div>
            </motion.div>
          
            <motion.div className="flex flex-col justify-between items-center"
                style={{
                }}
            >
                <motion.img src = {require("../assets/bulgari_watch5_optimized.png")} className="object-contain w-[13.5vw]"
                    style={{
                    }}
                >
                </motion.img>
                <div className="h-[50px] w-full justify-between flex flex-col items-center">
                    <img src = {require("../assets/dots.png")} className="object-contain max-w-[100px]"></img>
                    <div className="text-white text-center mb-0  product-name">
                        Octo Roma Grand Sonnerie
                    </div>
                </div>
            </motion.div> 

        </motion.div>
    )
}


export default WatchesDesktop;