import React, { useRef, useEffect, useState, useCallback } from "react";
import "./App.css";
import NewStart from "./components/NewStar";
import Snake from "./components/Snake";
import SerpentiNewSection from "./components/SerpentiNewSection";
import TubogasNewSection from "./components/TubogasNewSection";
import MoneteNewSection from "./components/MoneteNewSection";
import DivasNewSection from "./components/DivasNewSection";
import WatchSection from "./components/WatchSection";
import { useMotionValue, useMotionValueEvent, useScroll, useTransform, useInView, motion, useAnimation } from "framer-motion";
import { getDeviceViewportHeight } from "./hooks/GetDeviceHeight";

const App = () => {

    const scrolling = useRef(false);

    const currentScroll = useRef(0);
    const dir = useRef(0);
    const startTouchY = useRef(0);

    const container = useRef();
    const container2 = useRef();

    let touchStartY = 0;    
    let scrollTimeout = useRef();

    const [isIOS, setIsIOS] = useState(false);
    const [absolute, setAbsolute] = useState(false);
    const [hide_text, setHideText] = useState(false);
    const [change_fixed, setChangeFixed] = useState(false);
    const [showbg, setShowBG] = useState(false);

    const [color, setColor] = useState("yellow");

    const [blur, setBlur] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent;
        const ios = /iPhone|iPad|iPod/.test(userAgent);
        const safari = /Safari/.test(userAgent) && !/Chrome|CriOS|Edg/.test(userAgent);

        if (ios || safari) {
            setIsIOS(true);
        }
    }, []);

    const isDesktop = () => !('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);

    const onScroll = (el) => {

        if (!isDesktop()) return; // Skip execution if not a desktop device

        let prev = currentScroll.current;
        let curr = el.target.scrollTop;
        if(curr-prev>=1) {
            dir.current = 1
        } else {
            dir.current = -1;
        }
        if(Math.abs(curr-prev)>40) {
            console.log(dir.current, "dir current")
            handleScroll()
        }
        currentScroll.current = el.target.scrollTop
    }
    
    const handleScroll =  () => {
        if(!scrolling.current) {
            window.scrollBy({
                top: (window.innerHeight/300)*dir.current,
                behavior: 'smooth'
            })
            scrolling.current = true;
            scrollTimeout = setTimeout(() => {
                scrolling.current=false
            },800)
        } else {
        }
    }
    
    const handleTouchStart = (e) => {
        touchStartY = e.touches[0].clientY;
    };
    
    const handleTouchEnd = (e) => {
        
        const touchEndY = e.changedTouches[0].clientY;
        const direction = touchEndY > touchStartY ? -1 : 1;
     
        window.scrollBy({
            top: (window.innerHeight * 100) * direction,
            behavior: 'smooth',
        });
    
        // Throttle the scrolling
        if (!scrollTimeout.current) {
            
            window.scrollBy({
                top: (window.innerHeight * 100) * direction,
                behavior: 'smooth',
            });
           
            scrollTimeout.current = setTimeout(() => {
                scrollTimeout.current = null;
            }, 800);
        }
    };

    const handleScrollToTop = () => {
        console.log("blurrrrrr", container.current);
        if (blur) {

            if (isIOS) {
                setChangeFixed(true);
                setHideText(true);
                setTimeout(() => {
                    setHideText(false);
                }, 500);
                return;
            }

            setHideText(true);
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
            setTimeout(() => {
                setHideText(false);
            }, 500);
        }
    };

    return (
        
        <div className="">

            <div className={`container-fluid h-auto w-screen z-1 `}
                ref={ref => container2.current = ref}
            >
                
                {/* special bg */}
                <motion.div className="sticky w-screen top-0 flex items-end justify-center"
                    style = {{
                        height: getDeviceViewportHeight(),
                        opacity: (showbg ? 1 : 0),
                        backgroundColor: (color == "yellow" ? '#DE8B02' : '#014407')
                    }}
                >
                </motion.div>

                {/* to the top button */}
                <motion.div className="sticky w-screen top-0 flex items-end justify-center pb-[15px] z-[10000]"
                   onClick={handleScrollToTop}
                    style = {{
                        height: getDeviceViewportHeight()
                    }}
                    initial = {{opacity: 0}}
                    animate = {{opacity: blur ? 1 : 0}}
                    transition={{duration: .3, delay: blur ? .6 : 0}}
                >
                    <div className="flex flex-col items-center">
                        <img src = {require("./assets/arrow_up.png")} className="animate-down max-w-[20px]"></img>
                        <motion.div className="text-white text-center !mb-0 product-name times-new mt-[15px] !text-[10px]"
                            style={{
                            }}
                        >
                            Back To The Top
                        </motion.div>
                    </div>
                </motion.div>
               
                <NewStart setAbsolute = {setAbsolute} _ref = {container} change_fixed = {change_fixed} blur = {blur} setChangeFixed = {setChangeFixed}></NewStart>
                <Snake setShowBG={setShowBG}></Snake>
                <SerpentiNewSection setColor = {setColor} setShowBG={setShowBG}></SerpentiNewSection>
                <TubogasNewSection blur = {blur}></TubogasNewSection> 
                <MoneteNewSection blur = {blur}></MoneteNewSection>
                <WatchSection _setBlur = {setBlur} blur = {blur} hide_text = {hide_text}></WatchSection> 

            </div>

            {/* ios devices cant have this */}
            {
                (isIOS) ? null :
                <div className="scroller h-screen w-screen  top-0 left-0 z-99" 
                    onScroll={onScroll} 
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                    style={{ position: 'fixed', zIndex:999, overflowY:'scroll',overflowX:'hidden'}} 
                >
                    <div style={{background:'#ff000000',height: '50000px', width:'100vw'}}></div>
                </div> 
            }
        </div>
    );
}



export default App;
